import { ProfileQuery } from 'src/graphql/_generated';

export enum SlideId {
  organisation = 'organisation',
  customers = 'customers',
  softwareDev = 'softwareDev',
  supplyChain = 'supplyChain',
  cyberInsurance = 'cyberInsurance',
  regulations = 'regulations',
  assessmentDetails = 'assessmentDetails',
  scenarios = 'scenarios',
  finalise = 'finalise',
}

// export type WizardFormValues = NonNullable<NonNullable<ProfileQuery['profile']['activeWorkspace']>['questions']>;
export interface WizardFormValues {
  organisation: {
    sector?: CompanySector;
    annualRevenue?: number | null; //variable
    numberOfStaff?: number | null; //variable
    annualWageBill?: number | null; //variable
    avgWorkingDays?: number | null; //variable
    annualCyberSpend?: number | null; //variable
    avgStaffHardwareCost?: number | null; //variable
    usesManagedServiceProvider: boolean;
  };
  customers: {
    numberOfCustomers?: number | null; //variable
    avgContractValue?: number | null; //variable
    numberOfDataRecords?: number | null; //variable
  };
  softwareDev: {
    developsSoftware: boolean;
    releaseCycleCost?: number | null; //variable
    releaseCycleLength?: number | null; //variable
    researchAndDevelopmentBudget?: number | null; //variable
  };
  supplyChain: {
    avgCostToProcureSupplier?: number | null; //variable
    avgInvoiceValue?: number | null; //variable
    largestRegularInvoiceValue?: number | null; //variable
  };
  cyberInsurance: {
    cyberInsurance: boolean;
    cyberInsuranceLimit?: number | null; //variable
    cyberInsuranceExcess?: number | null; //variable
    cyberInsurancePremium?: number | null; //variable
  };
  regulations: {
    legislation: string[];
    controlFrameworks: CyberFramework[];
    controlFrameworksOther: string[];
    primaryControlFramework?: CyberFramework;
    primaryFrameworkControls: string[];
  };
  scenarios: {
    B2B: boolean;
    B2C: boolean;
    publiclyAccessibleInternetBusiness: boolean;
    paymentVolumeOrValue: boolean;
    significantITContributionToRevenue: boolean;
    significantDataInCloud: boolean;
    softwareBusiness: boolean;
    cniOrDefenseInvolvement: boolean;
    significantRandD: boolean;
    regulated: boolean;
    operationalTechnology: boolean;
    supplyChainReliant: boolean;
  };
  assessment: {
    id?: string;
    name?: string | null;
    description?: string | null;
    started_date?: string | null;
    review_date?: string | null;
  };
}

// ----------------------------------------------------   Company sector
export enum CompanySector {
  TECHNOLOGY = 'TECHNOLOGY',
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  HEALTH_CARE = 'HEALTH_CARE',
  BANKS = 'BANKS',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  INSURANCE = 'INSURANCE',
  REAL_ESTATE = 'REAL_ESTATE',
  AUTOMOBILES_AND_PARTS = 'AUTOMOBILES_AND_PARTS',
  CONSUMER_PRODUCTS_AND_SERVICES = 'CONSUMER_PRODUCTS_AND_SERVICES',
  MEDIA = 'MEDIA',
  RETAIL = 'RETAIL',
  TRAVEL_AND_LEISURE = 'TRAVEL_AND_LEISURE',
  FOOD_BEVERAGE_AND_TOBACCO = 'FOOD_BEVERAGE_AND_TOBACCO',
  PERSONAL_CARE_DRUG_AND_GROCERY_STORES = 'PERSONAL_CARE_DRUG_AND_GROCERY_STORES',
  CONSTRUCTION_AND_MATERIALS = 'CONSTRUCTION_AND_MATERIALS',
  INDUSTRIAL_GOODS_AND_SERVICES = 'INDUSTRIAL_GOODS_AND_SERVICES',
  BASIC_RESOURCES = 'BASIC_RESOURCES',
  CHEMICALS = 'CHEMICALS',
  ENERGY = 'ENERGY',
  UTILITIES = 'UTILITIES',
}

export const companySectorNaming = {
  [CompanySector.TECHNOLOGY]: 'Technology',
  [CompanySector.TELECOMMUNICATIONS]: 'Telecommunications',
  [CompanySector.HEALTH_CARE]: 'Health Care',
  [CompanySector.BANKS]: 'Banks',
  [CompanySector.FINANCIAL_SERVICES]: 'Financial Services',
  [CompanySector.INSURANCE]: 'Insurance',
  [CompanySector.REAL_ESTATE]: 'Real Estate',
  [CompanySector.AUTOMOBILES_AND_PARTS]: 'Automobiles and Parts',
  [CompanySector.CONSUMER_PRODUCTS_AND_SERVICES]: 'Consumer Products and Services',
  [CompanySector.MEDIA]: 'Media',
  [CompanySector.RETAIL]: 'Retail',
  [CompanySector.TRAVEL_AND_LEISURE]: 'Travel and Leisure',
  [CompanySector.FOOD_BEVERAGE_AND_TOBACCO]: 'Food, Beverage and Tobacco',
  [CompanySector.PERSONAL_CARE_DRUG_AND_GROCERY_STORES]: 'Personal Care, Drug and Grocery Stores',
  [CompanySector.CONSTRUCTION_AND_MATERIALS]: 'Construction and Materials',
  [CompanySector.INDUSTRIAL_GOODS_AND_SERVICES]: 'Industrial Goods and Services',
  [CompanySector.BASIC_RESOURCES]: 'Basic Resources',
  [CompanySector.CHEMICALS]: 'Chemicals',
  [CompanySector.ENERGY]: 'Energy',
  [CompanySector.UTILITIES]: 'Utilities',
};

export const companySectorOptions: { label: string; value: CompanySector }[] = [
  {
    value: CompanySector.TECHNOLOGY,
    label: companySectorNaming[CompanySector.TECHNOLOGY],
  },
  {
    value: CompanySector.TELECOMMUNICATIONS,
    label: companySectorNaming[CompanySector.TELECOMMUNICATIONS],
  },
  {
    value: CompanySector.HEALTH_CARE,
    label: companySectorNaming[CompanySector.HEALTH_CARE],
  },
  {
    value: CompanySector.BANKS,
    label: companySectorNaming[CompanySector.BANKS],
  },
  {
    value: CompanySector.FINANCIAL_SERVICES,
    label: companySectorNaming[CompanySector.FINANCIAL_SERVICES],
  },
  {
    value: CompanySector.INSURANCE,
    label: companySectorNaming[CompanySector.INSURANCE],
  },
  {
    value: CompanySector.REAL_ESTATE,
    label: companySectorNaming[CompanySector.REAL_ESTATE],
  },
  {
    value: CompanySector.AUTOMOBILES_AND_PARTS,
    label: companySectorNaming[CompanySector.AUTOMOBILES_AND_PARTS],
  },
  {
    value: CompanySector.CONSUMER_PRODUCTS_AND_SERVICES,
    label: companySectorNaming[CompanySector.CONSUMER_PRODUCTS_AND_SERVICES],
  },
  {
    value: CompanySector.MEDIA,
    label: companySectorNaming[CompanySector.MEDIA],
  },
  {
    value: CompanySector.RETAIL,
    label: companySectorNaming[CompanySector.RETAIL],
  },
  {
    value: CompanySector.TRAVEL_AND_LEISURE,
    label: companySectorNaming[CompanySector.TRAVEL_AND_LEISURE],
  },
  {
    value: CompanySector.FOOD_BEVERAGE_AND_TOBACCO,
    label: companySectorNaming[CompanySector.FOOD_BEVERAGE_AND_TOBACCO],
  },
  {
    value: CompanySector.PERSONAL_CARE_DRUG_AND_GROCERY_STORES,
    label: companySectorNaming[CompanySector.PERSONAL_CARE_DRUG_AND_GROCERY_STORES],
  },
  {
    value: CompanySector.CONSTRUCTION_AND_MATERIALS,
    label: companySectorNaming[CompanySector.CONSTRUCTION_AND_MATERIALS],
  },
  {
    value: CompanySector.INDUSTRIAL_GOODS_AND_SERVICES,
    label: companySectorNaming[CompanySector.INDUSTRIAL_GOODS_AND_SERVICES],
  },
  {
    value: CompanySector.BASIC_RESOURCES,
    label: companySectorNaming[CompanySector.BASIC_RESOURCES],
  },
];

// ----------------------------------------------------   Cyber frameworks
export enum CyberFramework {
  ISO_27002 = 'ISO_27002',
  NIST_CSF_V1 = 'NIST_CSF_V1',
  CIS_CONTROLS = 'CIS_CONTROLS',
  CYBER_ESSENTIALS = 'CYBER_ESSENTIALS',
  SOX = 'SOX',
  NIST_CSF_V2 = 'NIST_CSF_V2',
  CAF_BASIC_PROFILE = 'CAF_BASIC_PROFILE',
  CAF_ENHANCED_PROFILE = 'CAF_ENHANCED_PROFILE',
}

export const cyberFrameworkNaming = {
  [CyberFramework.ISO_27002]: 'ISO 27002',
  [CyberFramework.NIST_CSF_V1]: 'NIST CSF (v1)',
  [CyberFramework.CIS_CONTROLS]: 'CIS Controls',
  [CyberFramework.CYBER_ESSENTIALS]: 'Cyber Essentials',
  [CyberFramework.SOX]: 'SOX',
  [CyberFramework.NIST_CSF_V2]: 'NIST CSF (v2)',
  [CyberFramework.CAF_BASIC_PROFILE]: 'CAF (Basic Profile)',
  [CyberFramework.CAF_ENHANCED_PROFILE]: 'CAF (Enhanced Profile)',
};

export const cyberFrameworkOptions: { label: string; value: CyberFramework; extId: string }[] = [
  {
    value: CyberFramework.ISO_27002,
    label: cyberFrameworkNaming[CyberFramework.ISO_27002],
    extId: 'ISO27002',
  },
  {
    value: CyberFramework.NIST_CSF_V1,
    label: cyberFrameworkNaming[CyberFramework.NIST_CSF_V1],
    extId: 'NISTv1',
  },
  {
    value: CyberFramework.CIS_CONTROLS,
    label: cyberFrameworkNaming[CyberFramework.CIS_CONTROLS],
    extId: 'CIS',
  },
  {
    value: CyberFramework.CYBER_ESSENTIALS,
    label: cyberFrameworkNaming[CyberFramework.CYBER_ESSENTIALS],
    extId: 'CE',
  },
  {
    value: CyberFramework.SOX,
    label: cyberFrameworkNaming[CyberFramework.SOX],
    extId: 'SOX',
  },
  {
    value: CyberFramework.NIST_CSF_V2,
    label: cyberFrameworkNaming[CyberFramework.NIST_CSF_V2],
    extId: 'NISTv2',
  },
  {
    value: CyberFramework.CAF_BASIC_PROFILE,
    label: cyberFrameworkNaming[CyberFramework.CAF_BASIC_PROFILE],
    extId: 'CAF-OG-BP',
  },
  {
    value: CyberFramework.CAF_ENHANCED_PROFILE,
    label: cyberFrameworkNaming[CyberFramework.CAF_ENHANCED_PROFILE],
    extId: 'CAF-OG-EP',
  },
];
