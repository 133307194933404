import React, { useEffect, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { Divider, PageContainer, Spacer } from 'src/common';
import { queryCache, useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { ConfirmDeleteModal, IncidentModal } from 'src/components/modals';
import { QKeys } from 'src/api/types';
import MetaSheet from './comps/MetaSheet';
import { deleteIncident, getIncident } from 'src/api/incidents';
import _ from 'lodash';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import useVersion from 'src/hooks/useVersion';
import { useAuth } from 'src/state/auth';
import { AuditSection, LinkedScenariosSection } from './sections';

const IncidentPage = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { setLocationPaths } = useActionsApp();
  const { version } = useVersion();
  const { features } = useAuth();

  const { data: incident } = useQuery([QKeys.Incident, incidentId, { version }], () =>
    getIncident(incidentId as string, { version }),
  );

  useEffect(() => {
    if (incident) {
      setLocationPaths({ incident: { id: incident.id, name: incident.name } });
    }

    return () => {
      setLocationPaths({ incident: undefined });
    };
  }, [incident]);

  useGaEventPageView('Cydea | Security Incidents | Incident');

  const navigate = useNavigate();

  const [remove] = useMutation(deleteIncident, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.Incidents);
      navigate('/incidents');
    },
  });

  return (
    <>
      <Meta title={`Cydea | Security Incidents | Incident`} feedbackScript />

      {isDeleteModalOpen && (
        <ConfirmDeleteModal onConfirm={async () => remove(incidentId)} onClose={() => setIsDeleteModalOpen(false)} />
      )}
      {isEditModalOpen && (
        <IncidentModal initialData={_.omit(incident, 'scenarios')} onClose={() => setIsEditModalOpen(false)} />
      )}

      {incident && (
        <PageContainer data-cy="incident-page">
          <MetaSheet
            data={incident}
            editable={{
              onDeleteSelect: () => setIsDeleteModalOpen(true),
              onEditSelect: () => setIsEditModalOpen(true),
            }}
          >
            <Spacer $px={30} />
            <Divider />
            <LinkedScenariosSection version={version} incident={incident} />

            {features['app.audit.report'] && (
              <>
                <Divider />
                <AuditSection version={version} />
              </>
            )}
          </MetaSheet>
        </PageContainer>
      )}
    </>
  );
};

export default IncidentPage;
