import { FlatOrganisationsQuery } from 'src/graphql/_generated';
import { OrgWorkspaceNode } from './types';

type FlatTreeItem = FlatOrganisationsQuery['flatOrganisations'][number] & {
  children: FlatTreeItem[];
};

export interface TreeNode extends FlatTreeItem {
  children: TreeNode[];
}

export const buildTreeFromFlatOrg = (items: FlatOrganisationsQuery['flatOrganisations'], from?: string): TreeNode[] => {
  const orgMap = new Map<string, TreeNode>();
  const tree: TreeNode[] = [];

  items.forEach((item) => {
    orgMap.set(item.id, { ...item, children: [] });
  });

  items.forEach((item) => {
    const node = orgMap.get(item.id)!;

    if (item.parentId) {
      const parent = orgMap.get(item.parentId);
      if (parent) {
        parent.children.push(node);
      } else {
        tree.push(node);
      }
    } else {
      tree.push(node);
    }
  });

  if (from) {
    const startNode = orgMap.get(from);
    return startNode ? [startNode] : [];
  }

  return tree;
};

export const convertToTreeTableFormat = (nodes: TreeNode[]): OrgWorkspaceNode[] => {
  const countDirectWorkspaces = (node: TreeNode): number => {
    if (!node.children) return 0;
    return node.children.filter((child) => child.recordType === 'W').length;
  };

  return nodes.map((node) => ({
    key: node.id,
    data: {
      id: node.id,
      name: node.name,
      domain: node.domain,
      totalWorkspaces: node.recordType === 'O' ? countDirectWorkspaces(node) : undefined,
      primaryContact: node.primaryContact,
      recordType: node.recordType as 'O' | 'W',
      actionsOnThisOrgUnit: {
        canAccess: !!node.actionsOnThisOrgUnit?.canAccess,
        canRequest: !!node.actionsOnThisOrgUnit?.canRequest,
        canSeeSettings: !!node.actionsOnThisOrgUnit?.canSeeSettings,
      },
    },
    children: node.children ? convertToTreeTableFormat(node.children) : [],
  }));
};
