import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Assessment, SortType } from 'src/api/types';
import { ButtonSkeleton, Poppins, Settings } from 'src/common';
import CommonMenu from 'src/components/CommonMenu';
import Button from 'src/components/form/Button';
import { VariablesWorkspaceModal } from 'src/components/modals';
import { SortSelect } from 'src/components/select';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { WizardIntent } from 'src/redux/reducers/app/types';
import { useAssessment } from 'src/state/assessment';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import styled from 'styled-components';

const FiltersContainer = styled.div`
  display: flex;
  grid-gap: 10px;
  margin-left: auto;

  .cntrls {
    display: flex;
    grid-gap: 10px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .cydea-select {
      min-width: 200px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .cntrls {
      flex-direction: column-reverse;
    }
    .cydea-select {
      min-width: 200px;
    }
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 10px;
`;

interface HeadProps {
  compare: string[] | undefined;
  setCompare: (a: string[] | undefined) => void;
  isLoading: boolean;
  sortType: { value: SortType; dir: 'asc' | 'desc' };
  setSortType: (a: { value: SortType; dir: 'asc' | 'desc' }) => void;
  ordered: Assessment[];
}

export const Head: React.FC<HeadProps> = ({ compare, setCompare, isLoading, sortType, setSortType, ordered }) => {
  const [anchorEl, setAnchorEl] = useState<React.MouseEvent['currentTarget'] | null>(null);
  const [isVariablesModalOpen, setIsVariablesModalOpen] = useState(false);
  const { setWizardWorkspace } = useActionsApp();
  const { openModal } = useAssessment();

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb: () => void) => {
    e.stopPropagation();
    handleClose();
    cb();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isVariablesModalOpen && (
        <VariablesWorkspaceModal onClose={() => setIsVariablesModalOpen(false)} scope={{ type: 'workspace' }} />
      )}
      <CommonMenu
        onWorkspaceVariablesClick={(e) => onMenuClick(e, () => setIsVariablesModalOpen(true))}
        onWizardClick={(e) =>
          onMenuClick(e, () =>
            setWizardWorkspace({
              intent: {
                type: WizardIntent.assessment,
              },
            }),
          )
        }
        onAddAssessmentClick={(e) => onMenuClick(e, openModal)}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      />
      <Div>
        <Poppins px={32} weight={600}>
          Risk Assessments
        </Poppins>

        {!!compare ? (
          <div className="compare">
            <Poppins px={18} color="cflowerBlue">
              Select 2 cards to compare the assessments
            </Poppins>
            <FiltersContainer>
              <Button
                onClick={() => {
                  setCompare(undefined);
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Compare:cancel',
                    tags: ['ASSESSMENT'],
                  });
                }}
                secondary
                $constWidth={150}
              >
                CANCEL
              </Button>
              <Link
                to={`/compare/assessments/${compare[0]}/${compare[1]}`}
                onClick={() =>
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Compare:done',
                    tags: ['ASSESSMENT'],
                  })
                }
              >
                <Button disabled={compare?.length !== 2} primary $constWidth={150}>
                  DONE
                </Button>
              </Link>
            </FiltersContainer>
          </div>
        ) : (
          <>
            <FiltersContainer>
              {isLoading ? (
                <>
                  <ButtonSkeleton />
                  <ButtonSkeleton />
                </>
              ) : (
                <div className="cntrls">
                  <SortSelect
                    value={sortType.value}
                    onChange={(o) => {
                      setSortType({ value: o.value, dir: o.dir });
                      mpEvent(MPEvents.SortOrderChange, {
                        value: o.value,
                        tags: ['ASSESSMENT'],
                      });
                    }}
                  />
                  <div css="display: flex;grid-gap: 10px; justify-content: flex-end;">
                    {ordered.length > 1 && (
                      <Button
                        primary
                        $constWidth={155}
                        onClick={() => {
                          if (!compare) {
                            setCompare([]);
                            mpEvent(MPEvents.ButtonClick, {
                              button: 'Compare',
                              tags: ['ASSESSMENT'],
                            });
                          } else {
                            setCompare(undefined);
                          }
                        }}
                      >
                        COMPARE
                      </Button>
                    )}
                    <Settings
                      active={!!anchorEl}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(e.currentTarget);
                      }}
                      variant="button"
                      data-cy="common-menu"
                    />
                  </div>
                </div>
              )}
            </FiltersContainer>
          </>
        )}
      </Div>
    </>
  );
};
