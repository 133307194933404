import React, { useEffect, useState } from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import { ActionMeta, Props } from 'react-select';
import _ from 'lodash';
import { useAuth } from 'src/state/auth';

interface OrganisationSelectProps extends Omit<Props, 'value'> {
  value: string;
  onChange: (value: { label: string; value: string }, meta: ActionMeta<any>) => void;
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
  placeholder?: string;
  options?: { label: string; value: string }[];
  isSearchable?: boolean;
}

export const OrganisationSelect: React.FC<OrganisationSelectProps> = ({
  value,
  onChange,
  error,
  helperText,
  disabled,
  placeholder = 'Organisation',
  options,
  isSearchable,
}) => {
  const [orgOptions, setOrgOptions] = useState<{ label: string; value: string }[]>([]);
  const { flatOrganisations } = useAuth();

  useEffect(() => {
    if (!options) {
      const opt =
        flatOrganisations?.filter((o) => o.recordType === 'O').map((el) => ({ label: el.name, value: el.id })) || [];
      const sorted = _.orderBy(opt, [(item) => item.label.toLowerCase()]);
      setOrgOptions(sorted);
    } else {
      setOrgOptions(options);
    }
  }, [options, flatOrganisations]);

  return (
    <>
      <CSelect
        isDisabled={disabled}
        value={_.find(orgOptions, (o) => o.value === value)}
        placeholder={placeholder}
        options={orgOptions}
        onChange={onChange}
        isSearchable={isSearchable}
      />
      {error && helperText && <Error>{helperText}</Error>}
    </>
  );
};
