import React from 'react';
import { Tooltip } from 'src/common';
import styled from 'styled-components';
import { IconCopy, IconPaste } from './comps';
import { Modifiers, Strategy } from 'src/api/types';
import { useStateSelector } from 'src/redux';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { useAuth } from 'src/state/auth';
import { useFormikContext } from 'formik';
import { AiSvg } from 'src/assets/dynamicSvg';
import _ from 'lodash';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  /* border: 1px solid red; */
  .copy {
    position: absolute;
    top: 15px;
    right: 26px;
    display: flex;
    align-items: center;
    grid-gap: 9px;

    &__btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.white};
      filter: drop-shadow(0px 0px 10px ${({ theme }) => theme.colors.shadowLight});
    }
  }
`;

const normalize = (modifiers?: Modifiers | null) => {
  if (!modifiers) return null;

  return {
    treat: modifiers.treat.map((item) => _.omit(item, ['isLive', 'id', 'note'])),
    transfer: modifiers.transfer.map((item) => _.omit(item, ['isLive', 'id', 'note'])),
  };
};

const prepareModifiers = (currentValues: Modifiers, recommendedModifiers: Modifiers): Modifiers => {
  const treatTypeMap = new Map(currentValues.treat.map((item) => [item.type, item.id]));
  const transferTypeMap = new Map(currentValues.transfer.map((item) => [item.type, item.id]));

  return {
    treat: recommendedModifiers.treat.map((item) => {
      const cleanItem = _.omit(item, ['isLive']);
      return {
        ...cleanItem,
        id: treatTypeMap.get(item.type),
      };
    }),
    transfer: recommendedModifiers.transfer.map((item) => {
      const cleanItem = _.omit(item, ['isLive']);
      return {
        ...cleanItem,
        id: transferTypeMap.get(item.type),
      };
    }),
    notes: currentValues.notes,
  } as Modifiers;
};

interface PanelProps {
  strategy: Strategy | null;
  withCopy?: boolean;
  recommendedModifiers?: Modifiers | null;
}

export const Panel: React.FC<PanelProps> = ({ strategy, withCopy, recommendedModifiers }) => {
  const { values, isValid, setFieldValue } = useFormikContext<Modifiers>();
  const copiedModifiers = useStateSelector(({ app }) => app.copiedModifiers);
  const { setCopiedModifiers } = useActionsApp();
  const { features } = useAuth();

  let copiedStrategy: Strategy | null = null;

  if (copiedModifiers?.treat.length) {
    copiedStrategy = Strategy.Treat;
  }
  if (copiedModifiers?.transfer.length) {
    copiedStrategy = Strategy.Transfer;
  }

  const canCopy = (!!values.treat.length || !!values.transfer.length) && isValid;
  const canPaste = !!(copiedModifiers && copiedStrategy === strategy);

  const handlePaste = (modifiers: Modifiers) => {
    const existingTreatIds = new Set(values.treat.map((t) => t.id));
    const existingTransferIds = new Set(values.transfer.map((t) => t.id));
    const existingNotesIds = new Set(values.notes.map((t) => t.id));

    setFieldValue(
      'treat',
      modifiers.treat.map((el) => ({
        ...el,
        id: existingTreatIds.has(el.id) ? el.id : undefined,
      })),
    );
    setFieldValue(
      'transfer',
      modifiers.transfer.map((el) => ({
        ...el,
        id: existingTransferIds.has(el.id) ? el.id : undefined,
      })),
    );
    setFieldValue(
      'notes',
      modifiers.notes.map((el) => ({
        ...el,
        id: existingNotesIds.has(el.id) ? el.id : undefined,
      })),
    );
  };

  const canPasteSuggestion = !!recommendedModifiers && !_.isEqual(normalize(values), normalize(recommendedModifiers));

  return (
    <Div>
      <div className="copy">
        {features['app.ai.suggestModifiers'] && withCopy && (!!values.treat.length || !!values.transfer.length) && (
          <Tooltip
            dataHtml={canPasteSuggestion ? 'Suggest modifier' : ''}
            dataId="suggest"
            place="left"
            positionStrategy="fixed"
          >
            <div
              className="copy__btn"
              onClick={() => {
                if (canPasteSuggestion) {
                  const preparedModifiers = prepareModifiers(values, recommendedModifiers);
                  handlePaste(preparedModifiers);

                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Modifier:Suggest',
                    tags: ['MODIFIERS', 'AI'],
                  });
                }
              }}
              style={{ cursor: canPasteSuggestion ? 'pointer' : 'not-allowed' }}
              data-cy="copy-btn"
            >
              <AiSvg isActive={canPasteSuggestion} />
            </div>
          </Tooltip>
        )}
        {features['app.modifier.copy'] && withCopy && (
          <>
            <Tooltip dataHtml={canCopy ? 'Copy modifiers' : ''} dataId="copy" place="top" positionStrategy="fixed">
              <div
                className="copy__btn"
                onClick={() => canCopy && setCopiedModifiers(values)}
                style={{ cursor: canCopy ? 'pointer' : 'not-allowed' }}
                data-cy="copy-btn"
              >
                <IconCopy disabled={!canCopy} />
              </div>
            </Tooltip>
            <Tooltip dataHtml={canPaste ? 'Paste modifiers' : ''} dataId="paste" place="top" positionStrategy="fixed">
              <div
                className="copy__btn"
                onClick={() => canPaste && handlePaste(copiedModifiers)}
                style={{ cursor: canPaste ? 'pointer' : 'not-allowed' }}
                data-cy="paste-btn"
              >
                <IconPaste disabled={!canPaste} />
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </Div>
  );
};
