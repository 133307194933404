import React, { useState } from 'react';
import { Poppins } from 'src/common';
import Button from 'src/components/form/Button';
import { ContentWrap } from './styled';
import { useFiltersState } from '../../../state';
import { LocalFilters } from '../../../types';
import { mapToOptionList } from 'src/pages/DashboardPage/util';
import { GenericList } from './GenericList';
import _ from 'lodash';
import { FiltersType } from 'src/api/types/dashboard';
import { useAuth } from 'src/state/auth';

const parseToEventsState = (input: { label: string; tag: string; enabled: boolean }[]): FiltersType['owners'] => {
  const transformed = _.keyBy(input, 'label');

  const result = _.mapValues(transformed, (item) => ({
    tag: item.tag,
    enabled: item.enabled,
  }));

  return result;
};

interface ContentUsersProps {
  onClose: () => void;
  owners?: boolean;
}

export const ContentUsers: React.FC<ContentUsersProps> = ({ onClose, owners }) => {
  const { filters, setFilters } = useFiltersState();
  const [localOwners, setLocalOwners] = useState<LocalFilters[keyof LocalFilters]>(mapToOptionList(filters.owners));
  const [search, setSearch] = useState('');
  const { profile } = useAuth();

  const handleOnSelect = (label: string) => {
    setLocalOwners((prev) => prev.map((el) => (el.label === label ? { ...el, enabled: !el.enabled } : el)));
  };

  const handleOnSelectAll = (isAdd: boolean) => {
    setLocalOwners((prev) => {
      const shouldModify = (el: { label: string; enabled: boolean }) =>
        !search || el.label.toLowerCase().includes(search.toLowerCase());

      return prev.map((el) => (shouldModify(el) ? { ...el, enabled: isAdd } : el));
    });
  };

  const onApply = () => {
    const owners = parseToEventsState(localOwners);
    setFilters((prev) => ({ ...prev, owners }));
    onClose();
  };

  const isAllSelectedFromSearch = localOwners
    .filter((el) => el.label.toLowerCase().includes(search.toLowerCase()))
    .every((el) => el.enabled);

  return (
    <ContentWrap className="styled-scroll styled-scroll--rounded" style={{ width: 420 }}>
      <div className="chead gutter">
        <Poppins px={18} weight={500}>
          {owners ? 'Owners' : 'Users'}
        </Poppins>
        <Poppins
          className="chead__select-all"
          px={14}
          weight={500}
          onClick={() => handleOnSelectAll(!isAllSelectedFromSearch)}
        >
          Select All
        </Poppins>
      </div>
      <div className="ccontent">
        <GenericList
          filters={localOwners}
          handleOnSelect={handleOnSelect}
          // handleOnSelectAll={handleOnSelectAll}
          searchString={search}
          setSearchString={setSearch}
          type="assessments"
          emptyTextWithSearch="It looks like there aren’t any owners yet."
          checkIsYou={(opt) => new RegExp(profile?.user?.email as string, 'i').test(opt.label)}
        />
      </div>
      <div className="btns gutter">
        <Button secondary onClick={onClose}>
          CANCEL
        </Button>
        <Button primary onClick={onApply}>
          APPLY
        </Button>
      </div>
    </ContentWrap>
  );
};
