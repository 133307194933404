import React from 'react';
import { PickMenuItemParams } from './types';
import {
  IconAssistance,
  IconDelete,
  IconEdit,
  IconExportCsv,
  IconPublish,
  IconRiskTolerance,
  IconUnlinkScenario,
  IconUnlinkControl,
  IconUnlinkPlan,
  IconMakeAdmin,
  IconUnmakeAdmin,
  IconRemoveUser,
  IconRiskManagement,
  IconModifier,
  IconView,
  IconDuplicate,
  IconClear,
  IconAceptUser,
  IconInvite,
  IconWizard,
  IconVariable,
} from './icons';
import styled from 'styled-components';
import { Poppins } from 'src/common';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Item = styled.li`
  display: grid;
  grid-template-columns: 17px 1fr;
  align-items: center;
  grid-gap: 4px;
  padding: 4px 10px;

  span {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.cflowerBlue};

    &:hover {
      background: ${({ theme }) => theme.colors.gradient};
      -webkit-background-clip: text;
      color: transparent;
      cursor: pointer;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .MuiSvgIcon-root {
      font-size: 28px;
    }
  }
`;

type PickMenuItem = (
  a: PickMenuItemParams,
  b: { resetActiveItem: () => void; activeItem: string; setActiveItem: (a: string) => void },
) => JSX.Element | undefined;

export const pickMenuItem: PickMenuItem = (
  { type, label, exe, divider },
  { resetActiveItem, activeItem, setActiveItem },
) => {
  switch (type) {
    case 'VIEW':
      return (
        <div key="view">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:View',
              });
            }}
            onMouseEnter={() => setActiveItem('view')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-view"
          >
            <div className="icon-container">
              <IconView isActive={activeItem === 'view'} />
            </div>
            <Poppins px={16}>{label || 'View Detail'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'PUBLISH':
      return (
        <div key="publish">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:Publish',
              });
            }}
            onMouseEnter={() => setActiveItem('publish')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-publish"
          >
            <div className="icon-container">
              <IconPublish isActive={activeItem === 'publish'} />
            </div>
            <Poppins px={16}>{label || 'Publish'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'EDIT':
      return (
        <div key="edit">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:Edit',
              });
            }}
            onMouseEnter={() => setActiveItem('edit')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-edit"
          >
            <div className="icon-container">
              <IconEdit isActive={activeItem === 'edit'} />
            </div>
            <Poppins px={16}>{label || 'Edit'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'DELETE':
      return (
        <div key="delete">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:Delete',
              });
            }}
            onMouseEnter={() => setActiveItem('delete')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-delete"
          >
            <div className="icon-container">
              <IconDelete isActive={activeItem === 'delete'} />
            </div>
            <Poppins px={16}>{label || 'Delete'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'VARIABLES':
      return (
        <div key="var">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:Variables',
              });
            }}
            onMouseEnter={() => setActiveItem('var')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-variables"
          >
            <div className="icon-container">
              <IconVariable isActive={activeItem === 'var'} />
            </div>
            <Poppins px={16}>{label || 'Variables'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'RISK_TOLERANCE':
      return (
        <div key="risk">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:RiskTolerance',
              });
            }}
            onMouseEnter={() => setActiveItem('risk')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-risk"
          >
            <div className="icon-container">
              <IconRiskTolerance isActive={activeItem === 'risk'} />
            </div>
            <Poppins px={16}>{label || 'Risk Tolerance'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'CSV':
      return (
        <div key="csv">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:ExportCSV',
              });
            }}
            onMouseEnter={() => setActiveItem('csv')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-csv"
          >
            <div className="icon-container">
              <IconExportCsv isActive={activeItem === 'csv'} />
            </div>
            <Poppins px={16}>{label || 'Export CSV'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'PDF':
      return (
        <div key="pdf">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:ExportPDF',
              });
            }}
            onMouseEnter={() => setActiveItem('pdf')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-pdf"
          >
            <div className="icon-container">
              <IconExportCsv isActive={activeItem === 'pdf'} />
            </div>
            <Poppins px={16}>{label || 'Export PDF'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'MD':
      return (
        <div key="md">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:ExportMD',
              });
            }}
            onMouseEnter={() => setActiveItem('md')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-md"
          >
            <div className="icon-container">
              <IconExportCsv isActive={activeItem === 'md'} />
            </div>
            <Poppins px={16}>{label || 'Export MD'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'DUPLICATE':
      return (
        <div key="copy">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:Duplicate',
              });
            }}
            onMouseEnter={() => setActiveItem('copy')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-duplicate"
          >
            <div className="icon-container">
              <IconDuplicate isActive={activeItem === 'copy'} />
            </div>
            <Poppins px={16}>{label || 'Duplicate'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'ASSISTANCE':
      return (
        <div key="assistance">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:RequestAssistance',
              });
            }}
            onMouseEnter={() => setActiveItem('assistance')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-assistance"
          >
            <div className="icon-container">
              <IconAssistance isActive={activeItem === 'assistance'} />
            </div>
            <Poppins px={16}>{label || 'Request Assistance'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );

    case 'UNLINK_SCENARIO':
      return (
        <div key="un-s">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:UnlinkScenario',
              });
            }}
            onMouseEnter={() => setActiveItem('un-s')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-un-s"
          >
            <div className="icon-container">
              <IconUnlinkScenario isActive={activeItem === 'un-s'} />
            </div>
            <Poppins px={16}>{label || 'Unlink Scenario'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );

    case 'UNLINK_INCIDENT':
      return (
        <div key="un-inc">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:UnlinkIncident',
              });
            }}
            onMouseEnter={() => setActiveItem('un-inc')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-un-inc"
          >
            <div className="icon-container">
              <IconUnlinkScenario isActive={activeItem === 'un-inc'} />
            </div>
            <Poppins px={16}>{label || 'Unlink Incident'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );

    case 'UNLINK_CONTROL':
      return (
        <div key="un-cont">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:UnlinkControl',
              });
            }}
            onMouseEnter={() => setActiveItem('un-cont')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-un-cont"
          >
            <div className="icon-container">
              <IconUnlinkControl isActive={activeItem === 'un-cont'} />
            </div>
            <Poppins px={16}>{label || 'Unlink Control'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'UNLINK_PLAN':
      return (
        <div key="un-p">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:UnlinkPlan',
              });
            }}
            onMouseEnter={() => setActiveItem('un-p')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-un-p"
          >
            <div className="icon-container">
              <IconUnlinkPlan isActive={activeItem === 'un-p'} />
            </div>
            <Poppins px={16}>{label || 'Unlink Plan'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'MAKE_ADMIN':
      return (
        <div key="make-admin">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:MakeAdmin',
              });
            }}
            onMouseEnter={() => setActiveItem('make-admin')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-make-admin"
          >
            <div className="icon-container">
              <IconMakeAdmin isActive={activeItem === 'make-admin'} />
            </div>
            <Poppins px={16}>{label || 'Make admin'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'UNMAKE_ADMIN':
      return (
        <div key="unmake-admin">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:UnmakeAdmin',
              });
            }}
            onMouseEnter={() => setActiveItem('unmake-admin')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-unmake-admin"
          >
            <div className="icon-container">
              <IconUnmakeAdmin isActive={activeItem === 'unmake-admin'} />
            </div>
            <Poppins px={16}>{label || 'Revoke admin status'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'REMOVE_USER':
      return (
        <div key="remove-user">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:RemoveUser',
              });
            }}
            onMouseEnter={() => setActiveItem('remove-user')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-remove-user"
          >
            <div className="icon-container">
              <IconRemoveUser isActive={activeItem === 'remove-user'} />
            </div>
            <Poppins px={16}>{label || 'Remove user'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'ACCEPT_USER':
      return (
        <div key="accept-user">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:AcceptUser',
              });
            }}
            onMouseEnter={() => setActiveItem('accept-user')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-accept-user"
          >
            <div className="icon-container">
              <IconAceptUser isActive={activeItem === 'accept-user'} />
            </div>
            <Poppins px={16}>{label || 'Accept user'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'REJECT_USER':
      return (
        <div key="reject-user">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:RejectUser',
              });
            }}
            onMouseEnter={() => setActiveItem('reject-user')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-reject-user"
          >
            <div className="icon-container">
              <IconClear isActive={activeItem === 'reject-user'} />
            </div>
            <Poppins px={16}>{label || 'Reject user'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'CANCEL_INVITE':
      return (
        <div key="cancel-invite">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:CancelInvite',
              });
            }}
            onMouseEnter={() => setActiveItem('cancel-invite')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-cancel-invite"
          >
            <div className="icon-container">
              <IconClear isActive={activeItem === 'cancel-invite'} />
            </div>
            <Poppins px={16}>{label || 'Cancel invite'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'INVITE':
      return (
        <div key="invite">
          <Item
            onClick={exe}
            onMouseEnter={() => setActiveItem('invite')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-invite"
          >
            <div className="icon-container">
              <IconInvite isActive={activeItem === 'invite'} />
            </div>
            <Poppins px={16}>{label || 'Invite'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'ADD_TO_RMP':
      return (
        <div key="add-to-rmp">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:AddToRMP',
              });
            }}
            onMouseEnter={() => setActiveItem('add-to-rmp')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-add-to-rmp"
          >
            <div className="icon-container">
              <IconRiskManagement isActive={activeItem === 'add-to-rmp'} />
            </div>
            <Poppins px={16}>{label || 'Add to RMP'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'EXEC_SUMMARY':
      return (
        <div key="exec-summary">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:ExecutiveSummary',
              });
            }}
            onMouseEnter={() => setActiveItem('exec-summary')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-exec-summary"
          >
            <div className="icon-container">
              <IconRiskTolerance isActive={activeItem === 'exec-summary'} />
            </div>
            <Poppins px={16}>{label || 'Executive Summary'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'OPEN_MODIFIER':
      return (
        <div key="open-modifier">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:OpenModifier',
              });
            }}
            onMouseEnter={() => setActiveItem('open-modifier')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-open-modifier"
          >
            <div className="icon-container">
              <IconModifier isActive={activeItem === 'open-modifier'} />
            </div>
            <Poppins px={16}>{label || 'Open Modifier'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'ADD_CONTROL':
      return (
        <div key="add-control">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:AddControl',
              });
            }}
            onMouseEnter={() => setActiveItem('add-control')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-add-control"
          >
            <div className="icon-container">
              <IconRiskTolerance isActive={activeItem === 'add-control'} />
            </div>
            <Poppins px={16}>{label || 'Add Control'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'ADD_ASSESSMENT':
      return (
        <div key="add-assessment">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:AddAssessment',
              });
            }}
            onMouseEnter={() => setActiveItem('add-assessment')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-add-assessment"
          >
            <div className="icon-container">
              <IconRiskTolerance isActive={activeItem === 'add-assessment'} />
            </div>
            <Poppins px={16}>{label || 'Assessment'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'WIZARD':
      return (
        <div key="wizzard">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:AddAssessment',
              });
            }}
            onMouseEnter={() => setActiveItem('wizzard')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-wizzard"
          >
            <div className="icon-container">
              <IconWizard isActive={activeItem === 'wizzard'} />
            </div>
            <Poppins px={16}>{label || 'Assessment Wizard'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );
    case 'WORKSPACE_VARIABLES':
      return (
        <div key="add-vw">
          <Item
            onClick={(e) => {
              exe(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Elipsis:WorkspaceVariables',
              });
            }}
            onMouseEnter={() => setActiveItem('add-vw')}
            onMouseLeave={resetActiveItem}
            data-cy="common-menu-add-vw"
          >
            <div className="icon-container">
              <IconVariable isActive={activeItem === 'add-vw'} />
            </div>
            <Poppins px={16}>{label || 'Workspace Variables'}</Poppins>
          </Item>
          {divider && <div className="divider" />}
        </div>
      );

    default:
      return undefined;
  }
};
