import React from 'react';
import { OrgUser } from 'src/api/types';
import { CountLabel, GradientText, Spacer } from 'src/common';
import { TabWrap } from 'src/components/TabsMenu';
import styled from 'styled-components';
import _ from 'lodash';
import { UserModal } from 'src/components/modals';
import GenericTable from 'src/components/GenericTable';
import { useMembersColumns } from './useMembersColumns';
import { TableIds } from 'src/api/types/misc';
import { OrganisationQuery } from 'src/graphql/_generated';

const Div = styled(TabWrap)`
  background-color: ${({ theme }) => theme.colors.white};

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .label {
    color: ${({ theme }) => theme.colors.cflowerBlue};
    font-size: 22px;
    &__total {
      color: ${({ theme }) => theme.colors.prussianBlue};
      font-size: 24px;
      font-weight: 600;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.stroke};
  }
`;

interface MembersProps {
  orgId: string;
  members: NonNullable<OrganisationQuery['organisation']>['users'];
}

export const Members: React.FC<MembersProps> = ({ orgId, members }) => {
  const { columns, GTColumnSelectAnchorExported, profileModalId, setProfileModalId } = useMembersColumns({
    data: members,
    orgId,
  });

  return (
    <>
      {profileModalId && (
        <UserModal onClose={() => setProfileModalId(null)} externalUserId={profileModalId} orgId={orgId} />
      )}
      <Div>
        <GradientText>MEMBERS</GradientText>
        <Spacer $px={30} />
        <div className="head">
          <CountLabel label="member" length={members.length} />
        </div>
        <Spacer $px={20} />
        <GenericTable
          tableId={TableIds.orgMembers}
          data={members}
          columns={columns}
          GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
          searchable={['name', 'email', 'role']}
        />
      </Div>
    </>
  );
};
