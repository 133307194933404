import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Dash, Point } from './comps';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { getSectionOpenState, setSectionOpenState } from 'src/pages/DashboardPage/Filters/util';
import { useAuth } from 'src/state/auth';
import { ExpandedIndicator } from '../../../ExpandedIndicator';

interface DivProps {
  $contentMarginBottom: number;
  $highlight?: boolean;
}
const Div = styled.div<DivProps>`
  display: grid;
  grid-template-columns: 22px auto;
  grid-gap: 26px;
  position: relative;
  z-index: 1;

  .t-line {
    /* border: 1px solid red; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .t-content {
    position: relative;
    display: grid;
    grid-gap: 6px;
    margin-right: auto;
    max-width: calc(100% - 30px);
    flex: 1;
    transform: translateY(-2px);
    margin-bottom: ${({ $contentMarginBottom }) => $contentMarginBottom}px;

    &__head {
      cursor: pointer;
      user-select: none;
      display: inline-flex;
      align-items: center;
      grid-gap: 8px;
    }

    &__collapse {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      grid-gap: 6px;
      /* padding: 2px 0; */
      padding-top: 2px;
    }

    ${({ $highlight }) =>
      $highlight &&
      css`
        &::before {
          content: '';
          position: absolute;
          top: -10px;
          bottom: -12px;
          left: -15px;
          right: -15px;
          border-radius: 6px;
          background: ${({ theme }) => theme.colors.cultured};
          border: 1px solid ${({ theme }) => theme.colors.stroke};
          z-index: -1;
        }
      `}
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-gap: 20px;
  }
`;

interface RefActions {
  centerToday: () => void;
}

interface TimelineProps {
  isActive?: boolean;
  completeness?: number;
  headComponent: JSX.Element;
  content?: JSX.Element;
  isLast?: boolean;
  highlight?: boolean;
  contentMarginBottom?: number;
  onSelect?: () => void;
  ref?: React.Ref<RefActions>;
  dateKey?: string;
}

export const Timeline: React.FC<TimelineProps> = memo(
  forwardRef<RefActions, TimelineProps>(
    (
      {
        isActive,
        completeness,
        headComponent,
        content,
        isLast,
        highlight,
        contentMarginBottom = 20,
        onSelect,
        dateKey,
      },
      ref,
    ) => {
      const { profile } = useAuth();
      const key = `${profile?.activeWorkspace?.id}${dateKey}`;
      const [isOpen, setIsOpen] = useState(!getSectionOpenState({ id: key, storageKey: 'activity_sections' }));
      const innerRef = useRef<HTMLDivElement>(null);

      const centerToday = () => {
        if (innerRef.current) {
          const container = innerRef.current.closest('.container') as HTMLElement | null;
          if (container) {
            const elementRect = innerRef.current.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            const scrollTopPosition =
              innerRef.current.offsetTop - container.offsetTop - containerRect.height / 2 + elementRect.height / 2;
            container.scrollTop = scrollTopPosition;
          }
        }
      };

      useEffect(() => {
        // @ts-ignore
        if (ref?.current) {
          centerToday();
        }
      }, []);

      useEffect(() => {
        if (typeof ref === 'function') {
          ref({ centerToday });
        } else if (ref) {
          ref.current = { centerToday };
        }
      }, [ref]);

      return (
        <Div ref={innerRef} $contentMarginBottom={contentMarginBottom} $highlight={highlight} onClick={onSelect}>
          <div className="t-line">
            <Point isActive={isActive} />
            {!isLast && <Dash completeness={completeness} />}
          </div>
          <div className="t-content">
            <div
              className="t-content__head"
              onClick={() => {
                setIsOpen(!isOpen);
                if (dateKey) {
                  setSectionOpenState({
                    id: key,
                    isOpen,
                    storageKey: 'activity_sections',
                  });
                }
              }}
            >
              {headComponent}
              <ExpandedIndicator isOpen={isOpen} />
            </div>

            <motion.div
              initial={false}
              animate={{ height: isOpen ? 'unset' : 0 }}
              className="t-content__collapse"
              transition={{ type: 'tween' }}
            >
              {content}
            </motion.div>
          </div>
        </Div>
      );
    },
  ),
  (prev, next) => _.isEqual(prev, next),
);
