import React from 'react';
import { queryCache, useQuery } from 'react-query';
import { getScenarioControls } from 'src/api/plan';
import { QKeys, Scenario } from 'src/api/types';
import { NoData, Poppins, Spacer, TableSkeleton, getIncompleteState } from 'src/common';
import { Modifier } from 'src/components/Modifier';
import Button from 'src/components/form/Button';
import { getPercentage } from 'src/utils/misc';
import styled from 'styled-components';
import { useColumnsControls } from './useColumnsControls';
import GenericTable from 'src/components/GenericTable';
import { checkIsModifierStrategy } from 'src/components/Modifier/util';
import useVersion from 'src/hooks/useVersion';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import { AiSuggest } from '../../../comps/AiSuggest';
import { useAuth } from 'src/state/auth';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { convertToModifiers } from 'src/components/ProjectedRisk/util';

const Div = styled.div`
  .head {
    display: flex;
    justify-content: space-between;
  }

  .empty-label {
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.cflowerBlue};
  }

  .error-label {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gp1};
  }
`;

interface ControlsProps {
  scenario: Scenario;
}

export const Controls: React.FC<ControlsProps> = ({ scenario }) => {
  const { version } = useVersion();
  const { features } = useAuth();
  const { setLinkControlsToScenarioModal } = useActionsApp();

  const {
    data: scenarioControls = [],
    isLoading,
    error,
  } = useQuery([QKeys.ScenarioControls, scenario.id, { version }], () => getScenarioControls(scenario.id, { version }));

  const { columns, GTColumnSelectAnchorExported } = useColumnsControls({
    data: scenarioControls.map((el) => el),
    scenario: scenario,
  });

  console.log('scenarioControls', scenarioControls);

  const resetCache = async () => {
    await queryCache.invalidateQueries([QKeys.ScenarioControls, scenario.id, { version }]);
    queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: scenario.id, variant: 'base' }]);
    queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: scenario.id }]);
  };

  return (
    <>
      <Div>
        <div className="head">
          <Poppins weight={600}>RISK MANAGEMENT PLAN</Poppins>
          {!version && (
            <Button
              primary
              onClick={() => {
                setLinkControlsToScenarioModal(true);
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Add controls',
                  tags: ['SCENARIO', 'RMP'],
                });
              }}
              data-cy="add-controls-btn"
              $constWidth={200}
            >
              + ADD CONTROLS
            </Button>
          )}
        </div>
        {!isLoading ? (
          <>
            {features['app.ai.suggestControls'] && (
              <AiSuggest
                wrapperStyle={{ marginTop: -10, marginBottom: !!scenarioControls.length ? 4 : 10 }}
                scenario={scenario}
              />
            )}
            {scenarioControls.length ? (
              <>
                <Spacer $px={10} />
                <GenericTable
                  tableId={TableIds.scenarioControls}
                  data={scenarioControls.map((el) => el)}
                  columns={columns}
                  GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                  searchable={[
                    'strategy',
                    'name',
                    'owner',
                    'frameworkLibrary_shortname',
                    'controlId',
                    'status',
                    'updated_at',
                  ]}
                  expandContent={(plan) => {
                    const cont = plan.control_data;
                    if (getIncompleteState(scenario) === 'TECH') return null;
                    // if (!checkIsModifierStrategy(cont?.strategy) || getIncompleteState(scenario) === 'TECH')
                    //   return null;

                    const recommendedModifiers = cont.recommendedModifiers
                      ? convertToModifiers(cont.recommendedModifiers)
                      : null;

                    // console.log({
                    //   starting: {
                    //     starting_frequency: plan.starting_frequency,
                    //     starting_lower: plan.starting_lower,
                    //     starting_upper: plan.starting_upper,
                    //   },
                    //   scenario: {
                    //     frequency_times: scenario.frequency_times,
                    //     frequency_years: scenario.frequency_years,
                    //   },
                    // });

                    return (
                      <Modifier
                        projectedScenario={{
                          ...scenario,
                          frequency_times: getPercentage(scenario.frequency_times, scenario.frequency_years),
                        }}
                        startingValues={{
                          frequency_times: plan.starting_frequency * 100,
                          lower: plan.starting_lower,
                          upper: plan.starting_upper,
                        }}
                        disabled={!!version}
                        control={{
                          ...cont,
                          modifiers: cont.modifiers,
                        }}
                        onSave={resetCache}
                        withCopy
                        recommendedModifiers={recommendedModifiers}
                      />
                    );
                  }}
                />
              </>
            ) : (
              <>
                <Spacer $px={8} />
                {error ? <span className="error-label">Server error</span> : <NoData title="No controls linked" />}
              </>
            )}
          </>
        ) : (
          <TableSkeleton />
        )}
      </Div>
    </>
  );
};
