import React, { useEffect, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { PageContainer, PageOrgSkeleton, PermissionLabel, Poppins, Spacer, StyledAvatar } from 'src/common';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { getProfileCredentials } from 'src/utils/auth';
import { useAuth } from 'src/state/auth';
import TabsMenu from 'src/components/TabsMenu';
import { Details, Members } from './tabs';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useOrganisationQuery } from 'src/graphql/_generated';

const Div = styled(PageContainer)`
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 17px;
      grid-gap: 17px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .head {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 10px;
    }
  }
`;

const OrganisationSettingsPage = () => {
  const { id } = useParams<{ id?: string }>();
  const { isPlatformAdmin, profile, isOrganisationAdmin } = useAuth();
  const [activeTab, setActiveTab] = useState(1);
  const isSm = useBreakpoints('sm');
  const navigate = useNavigate();
  useGaEventPageView('Cydea | Organisation | Settings');

  useEffect(() => {
    if (!isOrganisationAdmin) {
      navigate('/home');
    }
  }, [isOrganisationAdmin]);

  const {
    data: organisationData,
    error,
    loading,
  } = useOrganisationQuery({
    variables: {
      id,
    },
  });

  return (
    <>
      <Meta title={`Cydea | Organisation | Settings`} feedbackScript />
      <>
        <Div>
          <Poppins px={32} weight={600}>
            Organisation Settings
          </Poppins>
          <Spacer $px={isSm ? 30 : 65} />
          {!!error && (
            <Poppins px={18} color={'error'}>
              {/* @ts-ignore */}
              {error.status} {error.msg || error.message}
            </Poppins>
          )}
          {loading ? (
            <PageOrgSkeleton />
          ) : (
            <>
              {organisationData?.organisation && (
                <>
                  <div className="head">
                    <div className="head__left">
                      <StyledAvatar $medium src={''}>
                        {getProfileCredentials(organisationData.organisation.name)}
                      </StyledAvatar>
                      <Poppins px={22} weight={600}>
                        {organisationData.organisation.name}
                      </Poppins>
                    </div>
                    <PermissionLabel
                      label={isPlatformAdmin ? 'Platform Admin' : profile?.user.orgUnitIdentity?.baseRole}
                    />
                  </div>
                  <Spacer $px={30} />
                  <TabsMenu
                    activeTab={activeTab}
                    setActiveTab={(tab) => {
                      setActiveTab(tab);
                      mpEvent(MPEvents.ButtonClick, {
                        button:
                          tab === 1 ? (isPlatformAdmin ? 'Tab:Details' : 'Tab:Organisation Details') : 'Tab:Members',
                        tags: ['ORGANISATION'],
                      });
                    }}
                    tabs={[
                      { label: isPlatformAdmin ? 'Details' : 'Organisation Details', value: 1 },
                      { label: 'Members', value: 2 },
                    ]}
                  />
                  {activeTab === 1 && <Details organisation={organisationData.organisation} />}
                  {activeTab === 2 && (
                    <Members orgId={organisationData.organisation.id} members={organisationData.organisation.users} />
                  )}
                </>
              )}
            </>
          )}
        </Div>
      </>
    </>
  );
};

export default OrganisationSettingsPage;
