import React, { useState, createContext, useContext, useEffect, useLayoutEffect } from 'react';
import { getUser } from 'src/api/auth';
import { useQuery } from 'react-query';
import { getIsSignedIn, LSKEY_INITAIL_URL } from 'src/utils/auth';
import { QKeys, User, UserOptionType, Variable } from 'src/api/types';
import { featuresMap } from './util';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { setCurrencySymbol } from 'src/utils/misc';
import {
  FlatOrganisationsQuery,
  ProfileQuery,
  useFlatOrganisationsLazyQuery,
  useProfileLazyQuery,
} from 'src/graphql/_generated';

interface AuthContextInterface {
  user: User | null;
  usersOptios: UserOptionType[];
  // DONE
  isSignedIn: boolean;
  setIsSignedIn: (a: boolean) => void;
  appLoaded: boolean;
  isOrganisationAdmin: boolean;
  isWorkspaceAdmin: boolean;
  isPlatformAdmin: boolean;
  isTscsRequiredToAccept: boolean;
  // GQL
  profile: ProfileQuery['profile'] | undefined;
  flatOrganisations: FlatOrganisationsQuery['flatOrganisations'];
  features: ReturnType<typeof featuresMap>;
  variables: Variable[];
  // wizardData: NonNullable<ProfileQuery['profile']['activeWorkspace']>['questions'] | undefined;
}

const AuthContext = createContext<Partial<AuthContextInterface>>({});

const AuthProvider = (props: any) => {
  const [isSignedIn, setIsSignedIn] = useState(getIsSignedIn());
  const [shouldInitialRedirect, setShouldInitialRedirect] = useState(true);
  const navigate = useNavigate();

  const [getProfile, { data: profile }] = useProfileLazyQuery();
  const [getFlatOrganisations, { data: flatOrganisations }] = useFlatOrganisationsLazyQuery();

  useEffect(() => {
    if (isSignedIn) {
      getProfile();
      getFlatOrganisations();
    }
  }, [isSignedIn]);

  const { data: user = null } = useQuery(QKeys.User, getUser, {
    enabled: isSignedIn,
    refetchInterval: 1000 * 60 * 60,
  });

  // console.log('profile', profile?.profile);

  useEffect(() => {
    if (profile) {
      Sentry.setUser({
        id: profile?.profile?.user.id,
        organisation_id: profile.profile.activeOrganisation?.id!,
        workspace_id: profile.profile.activeWorkspace?.id,
      });
      setCurrencySymbol(user?.workspaces.find((el) => el.active)?.currency || '');
    }
  }, [profile]);

  useLayoutEffect(() => {
    if (shouldInitialRedirect) {
      const initialUrl = window.localStorage.getItem(LSKEY_INITAIL_URL);

      if (profile?.profile.activeWorkspace) {
        const invites = user?.invites || [];
        if (user?.workspaces.length === 1 && invites.length === 0) {
          navigate(initialUrl || '/risk-assessments', { replace: true });
        } else {
          navigate(initialUrl || '/home', { replace: true });
        }

        window.localStorage.removeItem(LSKEY_INITAIL_URL);
        setShouldInitialRedirect(false);
      }
    }
  }, [location.pathname, shouldInitialRedirect, profile]);

  useLayoutEffect(() => {
    const allowedPaths = ['/home', '/get-started'];
    if (!!profile && profile.profile.activeWorkspace === null && !allowedPaths.includes(location.pathname)) {
      navigate('/home');
    }
  }, [location.pathname, profile]);

  const features = featuresMap(profile?.profile?.features || []);

  return (
    <AuthContext.Provider
      value={{
        user,
        usersOptios:
          profile?.profile.activeWorkspace?.owners?.map((el) => ({
            id: el.id,
            text: el.text,
            email: el.email,
            profile_photo_path: el.profilePhotoPath,
            invite_id: el.inviteId,
            member_id: el.memberId,
          })) || [],

        // DONE
        isSignedIn,
        setIsSignedIn,
        isOrganisationAdmin: profile?.profile.permissions.canEditUsers,
        isWorkspaceAdmin: profile?.profile.permissions.canEditUsers || profile?.profile.permissions.canManageUsers,
        appLoaded: Boolean(profile && flatOrganisations),
        isPlatformAdmin: profile?.profile?.permissions.isPlatformAdmin,
        isTscsRequiredToAccept:
          profile?.profile?.terms.status === 'REQUIRED' ||
          profile?.profile?.terms.status === 'NOT_AUTHORISED_TO_ACCEPT',

        // GQL
        profile: profile?.profile,
        flatOrganisations: flatOrganisations?.flatOrganisations || [],
        features,
        variables:
          profile?.profile.activeWorkspace?.variables?.map(
            (el) =>
              ({
                id: el.id!,
                name: el.name!,
                value: el.value!,
                currency: el.currency,
                isInUse: el.isInUse as string[],
                workspace_variable: el.workspaceVariable,
                alias: el.alias || null,
                isGlobal: !!el.isGlobal,
              }) satisfies Variable,
          ) || [],
        // wizardData: profile?.profile.activeWorkspace?.questions,
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context as AuthContextInterface;
};

export { AuthProvider, useAuth };
