import React, { useEffect, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { PageContainer, PageOrgSkeleton, PermissionLabel, Poppins, Spacer, StyledAvatar } from 'src/common';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { getProfileCredentials } from 'src/utils/auth';
import { useAuth } from 'src/state/auth';
import TabsMenu from 'src/components/TabsMenu';
import { Members, WorkspaceDetails, WorkspaceVariables } from './tabs';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useWorkspaceQuery } from 'src/graphql/_generated';

const Div = styled(PageContainer)`
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 17px;
      grid-gap: 17px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .head {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 10px;
    }
  }
`;

const WorkspaceSettingsScreen = () => {
  const { id } = useParams<{ id?: string }>();
  const { profile, isWorkspaceAdmin, isPlatformAdmin } = useAuth();
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const isSm = useBreakpoints('sm');
  useGaEventPageView('Cydea | Workspace | Settings');
  const workspaceId = id || (profile?.activeWorkspace?.id as string);

  useEffect(() => {
    if (!isWorkspaceAdmin) {
      navigate('/home');
    }
  }, [isWorkspaceAdmin]);

  const { data: workspaceData, error } = useWorkspaceQuery({
    variables: {
      id: workspaceId,
    },
  });

  return (
    <>
      <Meta title={`Cydea | Workspace | Settings`} feedbackScript />
      <>
        <Div>
          <Poppins px={32} weight={600}>
            Workspace Settings
          </Poppins>
          <Spacer $px={isSm ? 30 : 65} />
          {!!error && <Poppins px={18}>{error.message || 'Something went wrong'}</Poppins>}
          {workspaceData?.workspace ? (
            <>
              <div className="head">
                <div className="head__left">
                  <StyledAvatar $medium>{getProfileCredentials(workspaceData.workspace.name)}</StyledAvatar>
                  <Poppins px={22} weight={600}>
                    {workspaceData.workspace.name}
                  </Poppins>
                </div>
                <PermissionLabel
                  label={isPlatformAdmin ? 'Platform Admin' : profile?.user.orgUnitIdentity?.assumedRole}
                />
              </div>
              <Spacer $px={30} />

              <TabsMenu
                activeTab={activeTab}
                setActiveTab={(tab) => {
                  setActiveTab(tab);
                  mpEvent(MPEvents.ButtonClick, {
                    button: tab === 1 ? 'Tab:Workspace Details' : 'Tab:Members',
                    tags: ['WORKSPACE'],
                  });
                }}
                tabs={[
                  { label: 'Workspace Details', value: 1 },
                  { label: 'Members', value: 2 },
                  { label: 'Variables', value: 3 },
                ]}
              />
              {activeTab === 1 && <WorkspaceDetails workspace={workspaceData.workspace} />}
              {activeTab === 2 && <Members workspace={workspaceData.workspace} />}
              {activeTab === 3 && <WorkspaceVariables />}
            </>
          ) : (
            <PageOrgSkeleton />
          )}
        </Div>
      </>
    </>
  );
};

export default WorkspaceSettingsScreen;
