import { Assessment, Scenario } from 'src/api/types';
import { formatDate, numeralFormat } from 'src/utils/misc';

interface MDScenarioProps {
  scenario: Scenario;
}

export const generateScenarioMarkdown = ({ scenario }: MDScenarioProps): string => {
  let markdown = `
  <h2 id="${scenario.name.toLowerCase().replace(/ /g, '-')}">${scenario.name}</h2>
  `;

  markdown += `
  Expected Loss ${numeralFormat(scenario.expected_loss || 0)}
  `;

  return markdown;
};
