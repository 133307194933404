import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion, useMotionValue, animate, useTransform, PanInfo } from 'framer-motion';
import { useFiltersState } from '../../state';
import { ContentEvents, ContentUsers, ContentObjects } from './comps';
import { FiltersType } from 'src/api/types/dashboard';

const Div = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .content {
    height: 100%;
    display: flex;
    justify-content: flex-end;

    &__body {
      min-width: 365px;
      max-width: 500px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.white};
      filter: drop-shadow(0px 0px 20px ${({ theme }) => theme.colors.shadowLight});
    }
  }
`;

interface FModalProps {
  initialFilters?: FiltersType;
  owners?: boolean;
  hideIncidents?: boolean;
}

export const FModal: React.FC<FModalProps> = ({ initialFilters, owners, hideIncidents }) => {
  const { modalType, setModalType, setFilters } = useFiltersState();

  useEffect(() => {
    if (initialFilters) {
      setFilters(initialFilters);
    }
  }, [initialFilters]);

  const x = useMotionValue(500);

  const snapThreshold = 100;

  useEffect(() => {
    if (modalType) {
      x.set(500);
      animateModalOpen();
    }
  }, [modalType]);

  const backgroundOpacity = useTransform(x, [0, 500], [0.15, 0]);
  const backgroundColor = useTransform(backgroundOpacity, (opacity) => `rgba(0, 0, 0, ${opacity})`);

  const handleDragEnd = (_: MouseEvent, info: PanInfo) => {
    if (info.offset.x >= snapThreshold || info.velocity.x > 500) {
      animateModalClose();
      const timer = setTimeout(() => {
        setModalType(null);
        clearTimeout(timer);
      }, 500);
    } else {
      animateModalOpen();
    }
  };

  const animateModalClose = () => {
    animate(x, 500, {
      type: 'spring',
      stiffness: 300,
      damping: 30,
      onComplete: () => {
        setModalType(null);
      },
    });
  };

  const animateModalOpen = () => {
    animate(x, 0, {
      type: 'spring',
      stiffness: 300,
      damping: 30,
    });
  };

  return (
    <AnimatePresence>
      {modalType && (
        <Div style={{ backgroundColor }}>
          <motion.div
            className="content"
            style={{ x }}
            drag="x"
            dragConstraints={{ left: 0 }}
            dragElastic={0}
            onDragEnd={handleDragEnd}
          >
            <motion.div className="content__body">
              {modalType === 'users' && <ContentUsers onClose={animateModalClose} owners={owners} />}
              {modalType === 'events' && <ContentEvents onClose={animateModalClose} />}
              {modalType === 'objects' && <ContentObjects onClose={animateModalClose} hideIncidents={hideIncidents} />}
            </motion.div>
          </motion.div>
        </Div>
      )}
    </AnimatePresence>
  );
};
