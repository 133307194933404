import React from 'react';
import { Poppins, Spacer } from 'src/common';
import { ArrowButton, NextIcon } from 'src/components/Wizzard/comps';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import styled from 'styled-components';
import { RemindMeLater } from './RemindMeLater';
import VariableCSVReader from 'src/components/VariableCSVReader';
import { useAuth } from 'src/state/auth';
import useVariables from 'src/hooks/useVariables';
import { CsvVariable } from 'src/components/VariableCSVReader/types';

const Div = styled.div`
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
  }
`;

interface IntroProps {
  onClose: () => void;
  onProceed: () => void;
  onVariablesImport: (csvVariables: CsvVariable[]) => void;
}

export const Intro: React.FC<IntroProps> = ({ onClose, onProceed, onVariablesImport }) => {
  const { features } = useAuth();
  const { variables } = useVariables({});

  const handleVariablesApply = (csvVars: CsvVariable[]) => {
    onVariablesImport(csvVars);
  };

  const handleVariablesRevert = () => {
    onVariablesImport(
      variables?.map((v) => ({
        name: v.name,
        value: v.value,
        currency: v.currency,
      })) || [],
    );
  };

  return (
    <Div>
      <Spacer $px={10} />
      <div className="h-padding">
        <Poppins className="m-title" px={28}>
          Help Us Understand Your Organisation
        </Poppins>
        <Spacer $px={40} />
        <Poppins px={14} color="davysGrey">
          To help you create more accurate risk assessments, we need to gather more information about your organisation.
          Your responses will help us suggest relevant risk scenarios, pre-populate likelihood and consequence values,
          and create variables for reuse across multiple scenarios.
        </Poppins>
        <Spacer $px={20} />
        <Poppins px={14} color="davysGrey">
          If you need to gather information to answer the questions, you can leave and return to the wizard as many
          times as required. Approximate values are fine and can be changed or refined over time.
        </Poppins>
        <Spacer $px={20} />
        <Poppins px={14} color="davysGrey">
          You can save your answers and exit at any time.
        </Poppins>
        <Spacer $px={20} />
        {features['app.variables.import'] && (
          <VariableCSVReader
            onApply={handleVariablesApply}
            onRevert={handleVariablesRevert}
            originalVariables={variables || []}
          />
        )}
        <Spacer $px={30} />
        <div className="btns">
          <RemindMeLater
            onClose={() => {
              mpEvent(MPEvents.WizardClosed, {
                modal: 'Wizzard intro modal',
              });
              onClose();
            }}
          />

          <ArrowButton primary onClick={() => onProceed()} $constWidth={225}>
            <div className="content">
              PROCEED
              <NextIcon />
            </div>
          </ArrowButton>
        </div>
      </div>
    </Div>
  );
};
