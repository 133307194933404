import _ from 'lodash';

export const featuresMap = (featArr: { feature: string; isActive: boolean }[]) => {
  const evalFeat = (value: string) => {
    const feat = featArr.find((el) => el.feature === value);
    return feat ? !!feat.isActive : true;
  };

  return {
    'app.audit.controls.report': evalFeat('app.audit.controls.report'),
    'app.audit.report': evalFeat('app.audit.report'),
    'app.mixpanel': evalFeat('app.mixpanel'),
    'app.workspace.currency': evalFeat('app.workspace.currency'),
    'app.modifier.copy': evalFeat('app.modifier.copy'),
    'app.notifications.scheduler': evalFeat('app.notifications.scheduler'),
    'app.ai.suggestControls': evalFeat('app.ai.suggestControls'),
    'app.ai.suggestModifiers': evalFeat('app.ai.suggestModifiers'),
    'app.export.md': evalFeat('app.export.md'),
    'app.variables.import': evalFeat('app.variables.import'),
  };
};
