import React, { useState, useMemo } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { PageContainer, Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { useAuth } from 'src/state/auth';
import { Invites, Requests } from './comps';
import { OrganisationCreateModal, WorkspaceCreateModal } from 'src/components/modals';
import { useCache } from 'src/graphql/useCache';
import { buildTreeFromFlatOrg } from 'src/components/OrgTable/util';
import OrgTable from 'src/components/OrgTable';

const Div = styled(PageContainer)`
  .head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;

    &__buttons {
      display: flex;
      gap: 14px;
      grid-gap: 14px;
    }
  }
`;

const OrganisationHierarchyPage = () => {
  const [isOrganisationCreateModal, setIsOrganisationCreateModal] = useState(false);
  const [isWorspaceCreateModal, setIsWorspaceCreateModal] = useState(false);
  const { profile, flatOrganisations } = useAuth();
  const { refetchQuery } = useCache();
  useGaEventPageView('Cydea | Organisations');

  const orgTree = useMemo(() => buildTreeFromFlatOrg(flatOrganisations || []), [flatOrganisations]);

  const resetCache = () => refetchQuery({ keys: ['profile', 'flatOrganisations'] });

  return (
    <>
      <Meta title={`Cydea | Organisations`} feedbackScript />
      {isOrganisationCreateModal && <OrganisationCreateModal onClose={() => setIsOrganisationCreateModal(false)} />}
      {isWorspaceCreateModal && <WorkspaceCreateModal onClose={() => setIsWorspaceCreateModal(false)} />}
      <Div>
        <div className="head">
          <Poppins px={32} weight={600}>
            Organisations
          </Poppins>

          <div className="head__buttons">
            {profile?.permissions.canCreateOrganisation && (
              <Button secondary onClick={() => setIsOrganisationCreateModal(true)} $constWidth={300}>
                + CREATE ORGANISATION
              </Button>
            )}
            {profile?.permissions.canCreateWorkspace && (
              <Button secondary onClick={() => setIsWorspaceCreateModal(true)} $constWidth={300}>
                + CREATE WORKSPACE
              </Button>
            )}
          </div>
        </div>

        <Invites onSuccess={resetCache} />
        <Requests onSuccess={resetCache} />
        <Spacer $px={32} />
        <OrgTable data={orgTree} tableKey="organisations" />
      </Div>
    </>
  );
};

export default OrganisationHierarchyPage;
