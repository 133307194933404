import React, { useEffect, useState } from 'react';
import { Modal } from '../comps';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import { Assessment } from 'src/api/types';
import { MD } from './comps/MD';
import { generateAssessmentMarkdown } from './comps/MDAssessment';
import { useAuth } from 'src/state/auth';
import _ from 'lodash';

const StyledModal = styled(Modal)`
  width: 900px;
  min-height: 200px;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
  padding: 0;

  .divider {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.stroke};
  }
`;

interface MDExportModalProps {
  onClose: () => void;
  data: Assessment;
}

export const MDExportModal: React.FC<MDExportModalProps> = ({ onClose, data }) => {
  const [markdown, setMarkdown] = useState<string>('');
  const { user } = useAuth();

  useEffect(() => {
    const md = generateAssessmentMarkdown({
      assessment: data,
      userName: user?.name || user?.email || 'User',
    });
    setMarkdown(md);
  }, [data, user]);

  return (
    <>
      <StyledModal onRequestClose={() => onClose()} isOpen>
        <Spacer $px={54} />
        <div className="h-padding">
          <Poppins className="m-title" px={28}>
            Export MD
          </Poppins>
        </div>
        <Spacer $px={15} />
        <div className="divider" />
        <Spacer $px={15} />
        <div className="h-padding">
          <MD value={markdown} />
        </div>
        <Spacer $px={47} />
      </StyledModal>
    </>
  );
};
