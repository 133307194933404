import { RefetchQueriesOptions, useApolloClient } from '@apollo/client';
import { QueryKeys } from './types';

// type ClearQueryOptions = Record<string, any>;

export const useCache = () => {
  const client = useApolloClient();

  // Refetch specific queries
  const refetchQuery = async (params?: { keys?: QueryKeys[]; options?: RefetchQueriesOptions<any, any> }) => {
    const { keys, options } = params || {};

    return client.refetchQueries({
      include: keys?.length ? keys : 'active',
      ...options,
    });
  };

  // // Clear cache completely
  // const clearCache = async () => {
  //   await client.clearStore();
  // };

  // // Clear a specific query from the cache
  // const clearQuery = async (queryKey: QueryKeys, options?: ClearQueryOptions) => {
  //   await client.cache.evict({ fieldName: queryKey.toLowerCase(), ...options });
  //   await client.cache.gc();
  // };

  return {
    refetchQuery,
    // clearCache,
    // clearQuery,
  };
};
