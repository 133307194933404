import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'src/state/auth';
import { MPEvents, MpEvent } from './types';
import { getLocationParam } from './util';
import { isDev } from 'src/config';

const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN || 'null';
const isEnvReady = MIXPANEL_TOKEN !== 'null' && !isDev;

const useMixpanel = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const { profile, features } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setIsEnabled(features['app.mixpanel'] && isEnvReady);
  }, [features]);

  useEffect(() => {
    if (isEnabled) {
      mixpanel.init(MIXPANEL_TOKEN, {
        debug: process.env.NODE_ENV === 'development',
        persistence: 'localStorage',
        autotrack: true,
        ignore_dnt: true,
        track_pageview: false,
      });
    }
  }, [isEnabled]);

  useEffect(() => {
    if (isEnabled) {
      const extraParams = getLocationParam(location.pathname);
      mixpanel.track(MPEvents.PageView, {
        path: location.pathname,
        location: extraParams.location,
        tags: ['FE', ...extraParams.tags],
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isEnabled) {
      mixpanel.register({
        organizationID: profile?.activeOrganisation?.id,
        workspaceID: profile?.activeWorkspace?.id,
      });
    }
  }, [profile, isEnabled]);

  useEffect(() => {
    if (isEnabled) {
      if (profile?.user) {
        mixpanel.identify(String(profile.user.id));
        mixpanel.people.set({
          organizationID: profile?.activeOrganisation?.id,
          workspaceID: profile?.activeWorkspace?.id,
        });
      } else {
        mixpanel.reset();
      }
    }
  }, [profile, isEnabled]);
};

export const mpEvent: MpEvent = (event, params) => {
  if (!isEnvReady) return;

  let tags = params?.tags || [];

  if (event === MPEvents.Link || event === MPEvents.PageView) {
    tags = ['NAVIGATION', ...tags];
  }
  if (event === MPEvents.ButtonClick) {
    tags = ['BUTTON', ...tags];
  }
  if (event === MPEvents.GraphLegendToggle || event === MPEvents.GraphScaleSwitch) {
    tags = ['CHART', ...tags];
  }
  if (event === MPEvents.VariableAddedToFormula || event === MPEvents.FormulaVariablesOpen) {
    tags = ['VARIABLES', ...tags];
  }
  if (
    event === MPEvents.WizardSlideChanged ||
    event === MPEvents.WizardOpened ||
    event === MPEvents.WizardClosed ||
    event === MPEvents.WizardSubmit
  ) {
    tags = ['WIZARD', ...tags];
  }

  if (
    event === MPEvents.TableColumnsChange ||
    event === MPEvents.TableSortChange ||
    event === MPEvents.TableSearch ||
    event === MPEvents.TableRowExpand ||
    event === MPEvents.TableCheckboxClick
  ) {
    tags = ['TABLE', ...tags];
  }
  if (event === MPEvents.DateToTimelineLink) {
    tags = ['TIMELINE', ...tags];
  }

  tags.push('FE');

  try {
    mixpanel.track(event, { ...params, $tags: tags });
  } catch (error) {}
};

export default useMixpanel;
