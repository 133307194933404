import { Search } from '@mui/icons-material';
import React from 'react';
import { useStateSelector } from 'src/redux';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  input {
    height: 59px;
    width: 100%;
    outline: none;
    border: none;
    padding: 0 15px;
    color: ${({ theme }) => theme.colors.prussianBlue};
    background-color: ${({ theme }) => theme.colors.white};
    font-size: 16px;

    ::placeholder {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.cflowerBlue};
      font-weight: 500;
    }
  }
`;

interface HeaderProps {
  onChange: (value: string) => void;
}

export const Header: React.FC<HeaderProps> = ({ onChange }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);

  return (
    <Div>
      <Search
        style={{
          color: colors.prussianBlue,
        }}
      />
      <input
        type="search"
        onInput={(e) => {
          // @ts-ignore
          onChange(e.target.value);
          mpEvent(MPEvents.TableSearch, {});
        }}
        placeholder="Search by keyword ..."
      />
    </Div>
  );
};
