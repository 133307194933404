import { FieldArray } from 'formik';
import { FormikProvider } from 'formik';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { queryCache } from 'react-query';
import { updateWorkspaceVariables } from 'src/api/workspace';
import { Poppins, Spacer, TableSkeleton } from 'src/common';
import { Scope, SortState, VariableEdit } from './types';
import _ from 'lodash';
import { categorizeVariables, checkIsDirty, genEmptyVariable, sortVariables, validationSchema } from './util';
import { useFormik } from 'formik';
import useScenarioNames from './useMappedNames';
import { saveVariables } from 'src/api/assessment';
import CheckBox from 'src/components/form/CheckBox';
import { motion } from 'framer-motion';
import { Head, Row } from './comps';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { Div } from './styled';
import useAssessmentVariables from './useAssessmentVariables';
import { useAuth } from 'src/state/auth';
import { useCache } from 'src/graphql';
import VariableCSVReader from 'src/components/VariableCSVReader';
import { CsvVariable } from 'src/components/VariableCSVReader/types';

interface WorkspaceVariablesFormProps {
  onClose: () => void;
  scope: Scope;
  onDirtyChange?: (isDirty: boolean) => void;
  readOnly?: boolean;
  isModal?: boolean;
  hideEmptyVariablesControlled?: boolean;
}

const WorkspaceVariablesForm: React.FC<WorkspaceVariablesFormProps> = ({
  onClose,
  scope,
  onDirtyChange,
  isModal,
  hideEmptyVariablesControlled = true,
}) => {
  const [initialVariables, setInitialVariables] = useState<VariableEdit[]>([]);
  const [preCSVVariables, setPreCSVVariables] = useState<VariableEdit[]>([]);
  const [error, setError] = useState('');
  const [variablesRepo, setVariablesRepo] = useState<VariableEdit[]>([]);
  const [showAssessmentVarOnly, setShowAssessmentVarOnly] = useState(false);
  const [hideEmptyVariables, setHideEmptyVariables] = useState(hideEmptyVariablesControlled);
  const [sort, setSort] = useState<SortState>({ by: 'name', isAsc: true });
  const { variables: wVars, features } = useAuth();
  const { assessmentVariables, isAssessmentVariablesLoading } = useAssessmentVariables({
    assessmentId: scope.type === 'assessment' ? scope.id : undefined,
  });
  const { scenarioNames, assessmentNames } = useScenarioNames({
    variables: variablesRepo,
  });
  const { refetchQuery } = useCache();

  useEffect(() => {
    setHideEmptyVariables(hideEmptyVariablesControlled);
  }, [hideEmptyVariablesControlled]);

  useLayoutEffect(() => {
    if (!isAssessmentVariablesLoading) {
      const assessmentVariablesInit = assessmentVariables || [];
      const repo = [...wVars, ...assessmentVariablesInit];

      setVariablesRepo(repo);
      let vars = scope.type === 'workspace' ? wVars : repo;

      if (vars.length) {
        const duplicateNames = _(vars)
          .groupBy('name')
          .filter((group) => group.length > 1)
          .map((group) => group[0].name)
          .value();

        const fil = vars.filter((el) => !((el.workspace_variable || el.isGlobal) && duplicateNames.includes(el.name)));
        setInitialVariables(fil);
      } else {
        setInitialVariables([genEmptyVariable(scope.type)]);
      }
    }
  }, [isAssessmentVariablesLoading, scope, showAssessmentVarOnly, hideEmptyVariables, wVars]);

  const formik = useFormik({
    initialValues: {
      variables: initialVariables,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const promises: Promise<any>[] = [];

      if (!showAssessmentVarOnly) {
        const excludeDeleteNames = values.variables
          .filter((el) => !el.workspace_variable && !el.isGlobal)
          .map((el) => el.name);

        const wvInput = values.variables.filter((el) => el.workspace_variable || el.isGlobal);
        const wvOriginal = initialVariables.filter((el) => el.workspace_variable || el.isGlobal);
        const categorized = categorizeVariables(wvInput, wvOriginal, excludeDeleteNames, 'workspace');
        if (categorized.length) {
          promises.push(updateWorkspaceVariables(categorized));
        }
      }

      if (scope.type === 'assessment') {
        const assessmentInput = values.variables.filter((el) => !el.workspace_variable && !el.isGlobal);
        const assessmentOriginal = initialVariables.filter((el) => !el.workspace_variable && !el.isGlobal);
        const categorized = categorizeVariables(assessmentInput, assessmentOriginal, [], 'assessment');

        if (categorized.length) {
          promises.push(
            saveVariables({
              assessmentId: scope.id,
              variables: categorized,
            }),
          );
        }
      }

      return Promise.all(promises)
        .then(async () => {
          queryCache.invalidateQueries();
          await refetchQuery({ keys: ['profile'] });
          setPreCSVVariables([]);
          onClose();
        })
        .catch((err: Error) => setError(err.message || 'Something went wrong'));
    },
  });

  const { values, handleSubmit, isValid, isSubmitting, setFieldValue } = formik;

  useEffect(() => {
    const sorted = sortVariables(values.variables, sort);
    if (sorted) {
      setFieldValue('variables', sorted);
    }
  }, [sort, values]);

  const onOverride = (name: string, override: boolean) => {
    if (!override) {
      const newVariables = _.cloneDeep(values.variables);
      const idx = newVariables.findIndex((el) => el.name === name);
      const original = variablesRepo.find((el) => {
        return (el.isGlobal || el.workspace_variable) && el.name === name;
      });

      if (original) {
        newVariables[idx] = _.cloneDeep(original);
        setFieldValue(`variables`, newVariables);
      }
    } else {
      const newVariables = _.cloneDeep(values.variables);
      const idx = newVariables.findIndex((el) => el.name === name);

      const original = variablesRepo.find((el) => {
        return (el.isGlobal || el.workspace_variable) && el.name === name;
      });

      if (original) {
        const targetVariable = _.cloneDeep(original);
        newVariables[idx] = {
          ...targetVariable,
          workspace_variable: false,
          isGlobal: false,
          isInUse: [],
          _isNewOverwrite: true,
        };

        setFieldValue(`variables`, newVariables);
      }
    }
  };

  const handleCSVApply = (csvVariables: CsvVariable[]) => {
    if (!csvVariables.length) return;

    setPreCSVVariables(_.cloneDeep(values.variables));

    const newVariables = _.cloneDeep(values.variables);

    csvVariables.forEach((csvVar) => {
      const existingVarIndex = newVariables.findIndex((v) => v.name === csvVar.name);

      if (existingVarIndex !== -1) {
        newVariables[existingVarIndex] = {
          ...newVariables[existingVarIndex],
          value: csvVar.value || 0,
          currency: csvVar.currency,
        };
      } else {
        const newVar: VariableEdit = {
          id: Date.now(),
          name: csvVar.name,
          value: csvVar.value || 0,
          currency: csvVar.currency,
          workspace_variable: scope.type === 'workspace',
          isGlobal: false,
          isInUse: [],
          alias: null,
          _isNew: true,
          _isFromCSV: true,
        };
        newVariables.push(newVar);
      }
    });

    setFieldValue('variables', newVariables);
  };

  const handleCSVRevert = (csvVariables: CsvVariable[]) => {
    if (!csvVariables.length || !preCSVVariables.length) return;
    setFieldValue('variables', _.cloneDeep(preCSVVariables));
    setPreCSVVariables([]);
  };

  useEffect(() => {
    const referenceVariables = preCSVVariables.length > 0 ? preCSVVariables : initialVariables;
    onDirtyChange?.(checkIsDirty(values.variables, referenceVariables));
  }, [values.variables, preCSVVariables, initialVariables]);

  return (
    <Div>
      <div className="h-padding">
        <div className="vw-head">
          {isModal ? (
            <Poppins className="m-title" px={28}>
              {scope.type === 'assessment' ? 'Assessment' : 'Workspace'} Variables
            </Poppins>
          ) : (
            <div />
          )}
          {isModal && (
            <div className="vw-head__checks">
              {scope.type === 'assessment' && (
                <div
                  className="vw-head__avo-check"
                  onClick={() => setShowAssessmentVarOnly(!showAssessmentVarOnly)}
                  data-cy="show-assessment-variables-only"
                >
                  <Poppins color="cflowerBlue" px={14} weight={500}>
                    Show assessment variables only
                  </Poppins>
                  <CheckBox small isChecked={showAssessmentVarOnly} />
                </div>
              )}
              <div
                className="vw-head__avo-check"
                onClick={() => setHideEmptyVariables(!hideEmptyVariables)}
                data-cy="hide-empty-variables"
              >
                <Poppins color="cflowerBlue" px={14} weight={500}>
                  Hide empty variables
                </Poppins>
                <CheckBox small isChecked={hideEmptyVariables} />
              </div>
            </div>
          )}
        </div>
      </div>
      <Spacer $px={20} />
      <div className="divider" />
      <Spacer $px={24} />

      {!isAssessmentVariablesLoading ? (
        <FormikProvider value={formik}>
          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.1 }}
            onSubmit={handleSubmit}
          >
            <div className="h-padding">
              <FieldArray name="variables">
                {({ remove, push }) => {
                  return (
                    <>
                      <div className="rows-grid">
                        <Head sort={sort} setSort={setSort} />
                        {values.variables.map((el, idx) => {
                          const isVWar = el.workspace_variable || el.isGlobal;
                          const isOverride = !isVWar && wVars?.some((wv) => wv.name === el.name);
                          const canOverride = scope.type === 'assessment' && wVars?.some((wv) => wv.name === el.name);

                          const isHidden =
                            (hideEmptyVariables && el.value === null) || (showAssessmentVarOnly && isVWar);

                          return (
                            <Row
                              key={el.id || idx * 10000}
                              idx={idx}
                              onRemove={() => {
                                if (isOverride && !el._isNew) {
                                  onOverride(el.name, false);
                                } else {
                                  remove(idx);
                                }
                              }}
                              scenarioNames={scenarioNames}
                              assessmentNames={assessmentNames}
                              scope={scope}
                              isVWar={isVWar}
                              canOverride={!!canOverride}
                              isOverride={!!isOverride}
                              onOverride={() => onOverride(el.name, !isOverride)}
                              isHidden={isHidden}
                            />
                          );
                        })}
                      </div>
                      {scope.type === 'workspace' && features['app.variables.import'] && (
                        <>
                          <Spacer $px={15} />
                          <VariableCSVReader
                            onApply={handleCSVApply}
                            onRevert={handleCSVRevert}
                            originalVariables={variablesRepo}
                          />
                        </>
                      )}
                      <Spacer $px={35} />
                      <div className="btn-container">
                        <Button
                          secondary
                          disabled={isSubmitting}
                          onClick={() => {
                            push(genEmptyVariable(scope.type));
                            mpEvent(MPEvents.ButtonClick, {
                              button: 'Add new variable',
                              modal: isModal ? 'Variables modal' : undefined,
                              tags: ['VARIABLES'],
                            });
                          }}
                          data-cy="add-variable-btn"
                        >
                          ADD NEW VARIABLE
                        </Button>
                        <Button
                          type="submit"
                          primary
                          disabled={!isValid || !checkIsDirty(values.variables, initialVariables) || isSubmitting}
                          data-cy="save-variables-btn"
                          onClick={() =>
                            mpEvent(MPEvents.ButtonClick, {
                              button: 'Save',
                              modal: isModal ? 'Variables modal' : undefined,
                              tags: ['VARIABLES'],
                            })
                          }
                        >
                          SAVE
                        </Button>
                      </div>
                    </>
                  );
                }}
              </FieldArray>
              {error && (
                <>
                  <Spacer $px={23} />
                  <div className="error">{error}</div>
                  <Spacer $px={23} />
                </>
              )}
            </div>
          </motion.form>
        </FormikProvider>
      ) : (
        <div className="h-padding">
          <TableSkeleton />
          <Spacer $px={54} />
        </div>
      )}
    </Div>
  );
};

export default WorkspaceVariablesForm;
