import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from './comps';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MaterialInput from 'src/components/form/MaterialInput';
import { Poppins, Spacer } from 'src/common';
import { queryCache, useMutation } from 'react-query';
import Button from 'src/components/form/Button';
import { QKeys } from 'src/api/types';
import _ from 'lodash';
import { Guard } from './Guard';
import { inviteToOrg } from 'src/api/organisation';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useCache } from 'src/graphql';

const StyledModal = styled(Modal)`
  width: 566px;
  min-height: 200px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

interface WorkspaceAddUserModalProps {
  onClose: () => void;
  workspaceId: string;
  existingInvites: string[];
  existingRequests: string[];
  existingMembers: string[];
  initialValues?: { id?: string; name?: string; email?: string };
}

export const WorkspaceAddUserModal: React.FC<WorkspaceAddUserModalProps> = ({
  onClose,
  workspaceId,
  existingInvites,
  existingRequests,
  existingMembers,
  initialValues = {},
}) => {
  const [responseErr, setResponseErr] = useState('');
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const { refetchQuery } = useCache();

  const validationSchema = Yup.object({
    name: Yup.string().required('Required').max(100, 'Max 100 characters'),
    email: Yup.string()
      .required('Required')
      .email('Invalid email')
      .max(100, 'Max 100 characters')
      .notOneOf([...existingMembers, ...existingInvites, ...existingRequests], 'This email is already in use'),
  });

  const onSuccess = () => {
    refetchQuery({ keys: ['profile', 'workspace'] });
    onClose();
  };
  const onError = (err: any) => setResponseErr(err.message || 'Server error');

  const [invite] = useMutation(inviteToOrg, {
    onSuccess,
    onError,
  });

  const { values, handleBlur, handleChange, errors, touched, isSubmitting, handleSubmit, isValid, dirty } = useFormik({
    initialValues: {
      name: '',
      email: '',
      id: '',
      ...initialValues,
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      return invite({
        orgId: workspaceId,
        invitees: [
          {
            name: values.name,
            email: values.email,
            owner_id: values.id || undefined,
          },
        ],
      });
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen>
          <Spacer $px={30} />
          <div className="h-padding">
            <Poppins className="m-title" px={28}>
              Add a User
            </Poppins>
            <Spacer $px={20} />
            <MaterialInput
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Name"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
            />
            <Spacer $px={20} />
            <MaterialInput
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              type="email"
              placeholder="Email"
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              fullWidth
            />

            <Spacer $px={40} />

            <div className="btns">
              <Button
                secondary
                onClick={() => {
                  setIsCloseRequested(true);
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Cancel',
                    tags: ['WORKSPACE'],
                    modal: 'Workspace add user modal',
                  });
                }}
                primary
              >
                CANCEL
              </Button>
              <Button
                primary
                disabled={!isValid || isSubmitting || !dirty}
                onClick={() => {
                  handleSubmit();
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Invite',
                    tags: ['WORKSPACE'],
                    modal: 'Workspace add user modal',
                  });
                }}
              >
                INVITE
              </Button>
            </div>

            {!!responseErr && (
              <>
                <Spacer $px={15} />
                <div className="error">{responseErr}</div>
              </>
            )}
          </div>

          <Spacer $px={30} />
        </StyledModal>
      }
    />
  );
};
