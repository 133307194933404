export enum CSVVarHeaders {
  Name = 'Name',
  Value = 'Value',
  'Currency (yes/no)' = 'Currency (yes/no)',
}

export interface CsvVariable {
  name: string;
  value: number | undefined;
  currency: boolean;
}
