import React, { useState } from 'react';
import { Poppins, Spacer } from 'src/common';
import Form from './Form';
import { CsvVariable } from './types';
import { Variable } from 'src/api/types';
import { mpEvent } from 'src/utils/mixpanel';
import { MPEvents } from 'src/utils/mixpanel';
import { useStateSelector } from 'src/redux';
import styled from 'styled-components';

const Div = styled.div`
  .controls {
    display: flex;
    align-items: center;
    gap: 6px;
    display: inline-flex;
    cursor: pointer;
  }
`;

interface Props {
  originalVariables: Variable[];
  onApply: (variables: CsvVariable[]) => void;
  onRevert: (variables: CsvVariable[]) => void;
}

const VariableCSVReader: React.FC<Props> = ({ onApply, onRevert, originalVariables }) => {
  const [snapshotVariables, setSnapshotVariables] = useState<CsvVariable[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const colors = useStateSelector(({ theme }) => theme.colors);
  const [importSummary, setImportSummary] = useState<{ matched: number; total: number } | null>(null);

  const handleApply = (variables: CsvVariable[]) => {
    const snap: CsvVariable[] = [];
    const originalNames = originalVariables.map((v) => v.name);
    const matchedCount = variables.filter((v) => originalNames.includes(v.name)).length;

    variables.forEach((variable) => {
      const originalVariable = originalVariables.find((v) => v.name === variable.name);
      if (originalVariable) {
        snap.push({
          name: originalVariable.name,
          value: originalVariable.value,
          currency: originalVariable.currency,
        });
      }
    });

    setSnapshotVariables(snap);
    setImportSummary({ matched: matchedCount, total: variables.length });
    onApply(variables);
  };

  const handleRevert = () => {
    onRevert(snapshotVariables);
    setSnapshotVariables([]);
    setImportSummary(null);
  };

  return (
    <Div>
      <div
        className="controls"
        onClick={() => {
          setIsOpen(!isOpen);
          if (isOpen) handleRevert();
          mpEvent(MPEvents.ButtonClick, {
            button: 'Import from CSV',
            tags: ['VARIABLES'],
          });
        }}
      >
        <Poppins weight={500} style={{ textDecoration: 'underline' }}>
          Import from CSV
        </Poppins>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="6"
          viewBox="0 0 11 6"
          fill="none"
          style={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
        >
          <path
            d="M9.88629 5.93359H0.659141C0.480523 5.93359 0.326108 5.86838 0.195643 5.73806C0.0651783 5.60759 0 5.45318 0 5.27467C0 5.09616 0.0651783 4.94164 0.195643 4.81124L4.80923 0.197722C4.93984 0.0672574 5.09426 0.00193596 5.27273 0.00193596C5.45121 0.00193596 5.60577 0.0672574 5.73612 0.197722L10.3497 4.81128C10.48 4.94164 10.5455 5.09616 10.5455 5.2747C10.5455 5.45318 10.4801 5.60759 10.3497 5.73809C10.2194 5.86852 10.0648 5.93359 9.88629 5.93359Z"
            fill={colors.prussianBlue}
          ></path>
        </svg>
      </div>

      {isOpen && (
        <>
          <Spacer $px={15} />
          <Form onApply={handleApply} onRevert={handleRevert} />
          {/* {importSummary && (
            <>
              <Spacer $px={15} />
              <Poppins px={14} color={importSummary.matched > 0 ? 'success' : 'warning'}>
                Imported {importSummary.matched} variables out of {importSummary.total} from CSV.
                {importSummary.matched < importSummary.total &&
                  ` ${importSummary.total - importSummary.matched} variables were not matched to form fields.`}
              </Poppins>
            </>
          )} */}
        </>
      )}
    </Div>
  );
};

export default VariableCSVReader;
