import React, { useState } from 'react';
import styled from 'styled-components';
import { getProfileCredentials } from 'src/utils/auth';
import { Poppins, StyledAvatar } from 'src/common';
import Button from 'src/components/form/Button';
import { SimpleModal } from 'src/components/modals';
import { useMutation } from 'react-query';
import { workspaceJoinRequestCancel } from 'src/api/workspace';

const Div = styled.div`
  height: 79px;
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  border-radius: 6px;
  padding: 0 20px 0 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    position: relative;
    display: grid;
    grid-gap: 15px;
    grid-auto-flow: column;
    align-items: center;
  }

  .requested {
    display: flex;
    align-items: center;
    grid-gap: 40px;
  }
`;

const SButton = styled(Button)`
  height: 36px !important;
  font-size: 14px !important;
  width: 180px;
  padding: 0;
`;

const RejectBtn = styled(SButton)`
  background: ${({ theme }) => theme.colors.white};
  -webkit-background-clip: none;
  -webkit-text-fill-color: ${({ theme }) => theme.colors.gp1};
`;

interface RowRequestProps {
  id: string;
  name: string;
  img: string | null;
  onSuccess: () => void;
}

export const RowRequest: React.FC<RowRequestProps> = ({ id, name, img, onSuccess }) => {
  const [err, setErr] = useState('');

  const [cancel, { isLoading: isLoadingCancel }] = useMutation(workspaceJoinRequestCancel, {
    onMutate: () => setErr(''),
    onSuccess,
    onError: (err: any) => setErr(err?.message),
  });

  console.log('id', id);

  return (
    <>
      {err && <SimpleModal content={<span>{err}</span>} onClose={() => setErr('')} onOk={() => setErr('')} />}
      <Div>
        <div className="left">
          <StyledAvatar $small src={img || ''} alt="">
            {getProfileCredentials(name)}
          </StyledAvatar>
          <Poppins px={14} weight={500} color={'prussianBlue'}>
            {name}
          </Poppins>
        </div>

        <div className="requested">
          <Poppins px={14} weight={500}>
            Access requested
          </Poppins>
          <RejectBtn secondary onClick={() => cancel(id)} disabled={isLoadingCancel} css="width: 180px">
            CANCEL REQUEST
          </RejectBtn>
        </div>
      </Div>
    </>
  );
};
