import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useStateSelector } from 'src/redux';
import { OrgWorkspaceNode } from '../types';

const StyledLink = styled(Link)`
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    svg {
      fill: url(#icon-gradient);
    }
  }

  &::before {
    content: '';
    z-index: -1;
    top: -10px;
    left: -2px;
    position: absolute;
    width: 45px;
    height: 45px;
    background: transparent;
  }
`;

const MotionStyledLink = motion(StyledLink);

interface OrgSettingsBtnProps {
  data: OrgWorkspaceNode['data'];
}

export const OrgSettingsBtn: React.FC<OrgSettingsBtnProps> = ({ data }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);

  if (!data.actionsOnThisOrgUnit.canSeeSettings) return null;

  return (
    <MotionStyledLink
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 500, damping: 15 }}
      to={`/organisation/${data.id}`}
      onClick={() =>
        mpEvent(MPEvents.Link, {
          source: { value: ['Organisations'] },
          destination: { value: ['Organisation Settings'], params: { id: data.id } },
        })
      }
      data-cy="gear-icon"
    >
      <SettingsIcon
        css={`
          color: ${colors.cflowerBlue};
          font-size: 24px;
        `}
      />
    </MotionStyledLink>
  );
};
