import { useSetActiveWorkspaceMutation } from 'src/graphql/_generated';

type SwitchOrgParams = string | { workspaceId: string; redirectUrl?: string };

type UseWorkspaceSwitch = (params?: { onError?: (data: any) => void }) => {
  switchOrg: (params: SwitchOrgParams) => void;
  isLoading: boolean;
  error: any;
};

const useWorkspaceSwitch: UseWorkspaceSwitch = (options = {}) => {
  const { onError } = options;

  const [setActiveWorkspace, { loading, error }] = useSetActiveWorkspaceMutation();

  const switchOrg = async (params: SwitchOrgParams) => {
    const workspaceId = typeof params === 'string' ? params : params.workspaceId;
    await setActiveWorkspace({ variables: { workspaceId } })
      .then(() => {
        let redirectUrl;

        if (typeof params === 'object' && 'redirectUrl' in params) {
          redirectUrl = params.redirectUrl;
        }

        window.location.replace(redirectUrl || '/');
      })
      .catch((err) => onError?.(err));
  };

  return { switchOrg, isLoading: loading, error: error };
};

export default useWorkspaceSwitch;
