import React from 'react';
import styled from 'styled-components';
import { convertToTreeTableFormat, TreeNode } from './util';
import { TreeTable, TreeTableSortEvent } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { useState, useCallback, useMemo } from 'react';
import { TextOverflowTooltip } from 'src/common';
import { OrgWorkspaceNode } from './types';
import { Header } from './comps/Header';
import { Actions, OrgAvatar, OrgSettingsBtn, SortIcon } from './comps';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { get } from 'lodash';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin: 0;
    padding: 0;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  overflow-y: auto;
  border-radius: 6px;
  min-width: 700px;

  .p-treetable-header {
    display: flex;
    align-items: center;
    height: 59px;
    background-color: ${({ theme }) => theme.colors.white};
    padding-left: 18px;
  }

  .p-treetable {
    margin: 0;
    padding: 0;

    .p-treetable-wrapper {
      margin: 0;
      padding: 0;
    }

    table {
      margin: 0;
      padding: 0;
    }

    .p-column-header-content {
      display: flex;
      align-items: center;

      .p-column-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .p-treetable-thead {
      background-color: ${({ theme }) => theme.colors.white};
    }

    .p-treetable-thead {
      .c-name {
        width: 30%;
      }
      .c-acc-type {
        width: 15%;
      }
    }

    .p-treetable-thead > tr > th {
      border: none;
      color: ${({ theme }) => theme.colors.prussianBlue};
      border-top: 1px solid ${({ theme }) => theme.colors.stroke};
      padding: 0 12px 0 10px;
      height: 59px;
      font-size: 18px;
      font-weight: 600;
      user-select: none;
      text-align: left;
      margin: 0;
    }
    .p-treetable-thead > tr > th:first-child {
      padding: 0 12px 0 58px;
    }

    .p-treetable-tbody > tr {
      color: ${({ theme }) => theme.colors.prussianBlue};
      border-top: 1px solid ${({ theme }) => theme.colors.stroke};
      background-color: ${({ theme }) => theme.colors.white};
      font-size: 14px;
      font-weight: 500;
      height: 77px;

      &.expandable {
        cursor: pointer;
      }

      &:hover {
        background: ${({ theme }) => theme.colors.cultured};
      }

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      > td:first-child {
        padding: 0 12px 0 54px;
      }
      > td {
        padding: 0 12px 0 10px;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .p-treetable-toggler {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      color: ${({ theme }) => theme.colors.prussianBlue};
    }
  }

  .c-actions {
    position: relative;
    padding-right: 28px;
    width: 210px;
  }

  .c-tdm {
    width: 0px !important;
    position: relative;
    justify-content: center;
    transform: translateX(-50px);
    align-items: center;

    &__content {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
`;

const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface OrgTableProps {
  data: TreeNode[];
  tableKey: string;
}

const OrgTable: React.FC<OrgTableProps> = ({ data, tableKey }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useLocalStorage<string>(`org-table-sort-field-${tableKey}`, '');
  const [sortOrder, setSortOrder] = useLocalStorage<0 | 1 | -1 | null | undefined>(
    `org-table-sort-order-${tableKey}`,
    1,
  );

  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>(() => {
    return data.reduce(
      (acc, node) => ({
        ...acc,
        [node.id]: true,
      }),
      {},
    );
  });

  const treeTableData = useMemo(() => convertToTreeTableFormat(data), [data]);

  const customSortFunction = useCallback(
    (data: OrgWorkspaceNode[]): OrgWorkspaceNode[] => {
      return [...data].sort((a, b) => {
        if (a.data.recordType !== b.data.recordType) {
          return a.data.recordType === 'W' ? -1 : 1;
        }

        if (sortField && sortOrder !== 0 && sortOrder !== null && sortOrder !== undefined) {
          let valueA = get(a.data, sortField);
          let valueB = get(b.data, sortField);

          if (valueA === undefined) return 1;
          if (valueB === undefined) return -1;

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return sortOrder * valueA.localeCompare(valueB);
          }

          return sortOrder * (valueA - valueB);
        }

        return 0;
      });
    },
    [sortField, sortOrder],
  );

  const applyCustomSort = useCallback(
    (dataArray: OrgWorkspaceNode[]): OrgWorkspaceNode[] => {
      const sortedData = customSortFunction(dataArray);

      return sortedData.map((node) => {
        if (node.children && node.children.length > 0) {
          return {
            ...node,
            children: applyCustomSort(node.children),
          };
        }
        return node;
      });
    },
    [customSortFunction],
  );

  const filterData = useCallback((data: OrgWorkspaceNode[], term: string): OrgWorkspaceNode[] => {
    if (!term.trim()) return JSON.parse(JSON.stringify(data));

    return data
      .filter((node) => {
        const matchesSearch =
          node.data.name.toLowerCase().includes(term.toLowerCase()) ||
          node.data.domain.toLowerCase().includes(term.toLowerCase()) ||
          node.data.id.toLowerCase().includes(term.toLowerCase()) ||
          node.data.primaryContact.toLowerCase().includes(term.toLowerCase());

        if (matchesSearch) return true;

        if (node.children && node.children.length > 0) {
          const filteredChildren = filterData(node.children, term);
          if (filteredChildren.length) {
            return true;
          }
        }

        return false;
      })
      .map((node) => {
        if (node.children && node.children.length > 0) {
          const filteredChildren = filterData(node.children, term);
          return {
            ...node,
            children: filteredChildren,
          };
        }
        return node;
      });
  }, []);

  const onSort = (event: TreeTableSortEvent) => {
    if (sortField === event.sortField) {
      if (sortOrder === 1) {
        setSortOrder(-1);
      } else if (sortOrder === -1) {
        setSortField('');
        setSortOrder(0);
      } else {
        setSortField(event.sortField);
        setSortOrder(1);
      }
    } else {
      setSortField(event.sortField);
      setSortOrder(1);
    }
  };

  const filteredData = useMemo(() => {
    const filtered = filterData(treeTableData, searchTerm);
    return applyCustomSort(filtered);
  }, [filterData, treeTableData, searchTerm, applyCustomSort]);

  return (
    <Container>
      <TableContainer>
        <TreeTable
          value={filteredData}
          header={<Header onChange={setSearchTerm} />}
          onSort={onSort}
          filterMode="strict"
          sortOrder={sortOrder}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          rowClassName={({ children }) => ({ expandable: children?.length ? true : false })}
          onRowClick={(e) => {
            if (e.node.children) {
              const newExpandedKeys = { ...expandedKeys };
              //   @ts-ignore
              if (newExpandedKeys[e.node.key]) {
                //   @ts-ignore
                delete newExpandedKeys[e.node.key];
              } else {
                //   @ts-ignore
                newExpandedKeys[e.node.key] = true;
              }
              setExpandedKeys(newExpandedKeys);
            }
          }}
          sortIcon={() => null}
        >
          <Column
            field="name"
            header={
              <ColumnHeader>
                Name <SortIcon sortOrder={sortField === 'name' ? sortOrder || -1 : 0} />
              </ColumnHeader>
            }
            expander
            sortable
            className="c-name"
            body={({ data }) => (
              <OrgAvatar name={data.name} imgUrl={data.imgUrl} gradientBlue={data.recordType === 'W'} />
            )}
          />
          <Column
            field="type"
            header={
              <ColumnHeader>
                Account Type <SortIcon sortOrder={sortField === 'type' ? sortOrder || -1 : 0} />
              </ColumnHeader>
            }
            sortable
            body={({ data }) => (data.recordType === 'O' ? 'Organisation' : 'Workspace')}
          />
          <Column
            field="primaryContact"
            header={
              <ColumnHeader>
                Primary Contact <SortIcon sortOrder={sortField === 'primaryContact' ? sortOrder || -1 : 0} />
              </ColumnHeader>
            }
            sortable
            body={({ data }) => <TextOverflowTooltip>{data.primaryContact}</TextOverflowTooltip>}
          />
          <Column
            field="domain"
            header={
              <ColumnHeader>
                Domain <SortIcon sortOrder={sortField === 'domain' ? sortOrder || -1 : 0} />
              </ColumnHeader>
            }
            sortable
            body={({ data }) => <TextOverflowTooltip>{data.domain?.replace(/^@/, '')}</TextOverflowTooltip>}
          />
          <Column field="" header="" className="c-actions" body={({ data }) => <Actions data={data} />} />
          <Column
            field=""
            header=""
            className="c-tdm"
            body={({ data }: OrgWorkspaceNode) => {
              return (
                data.recordType === 'O' && (
                  <div className="c-tdm__content">
                    <OrgSettingsBtn data={data} />
                  </div>
                )
              );
            }}
          />
        </TreeTable>
      </TableContainer>
    </Container>
  );
};

export default OrgTable;
