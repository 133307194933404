import { AxiosArgs, ThenArgs, OrganisationWorkspace, UserOrgProfile, OrgUser } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody } from 'src/api/client';

type CreateOrg = (params: {
  name: string;
  primary_contact: string;
  billing_contact: string | null;
  parentOrganisation: string;
  domain: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace>>>>;

export const createOrg: CreateOrg = (params) => {
  const path = `/api/public/api/organisations`;
  return axios({
    method: 'POST',
    data: encodeBody({ ...params, domain: `@${params.domain}` }),
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateOrg = (params: {
  id: string;
  name: string;
  primary_contact: string;
  billing_contact?: string | null;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace>>>>;

export const updateOrg: UpdateOrg = ({ id, ...params }) => {
  const path = `/api/public/api/organisations/admin/${id}`;
  return axios({
    method: 'PUT',
    data: encodeBody(params),
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------
type RejetcUserJoinRequest = (a: {
  orgId: string;
  requestId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const rejetcUserJoinRequest: RejetcUserJoinRequest = ({ orgId, requestId }) => {
  const path = `/api/public/api/organisations/admin/${orgId}/reject/${requestId}`;
  return axios({
    method: 'PUT',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type InviteToOrg = (a: {
  orgId: string;
  invitees: { owner_id?: string; email: string; name: string }[];
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const inviteToOrg: InviteToOrg = ({ orgId, ...data }) => {
  const path = `/api/public/api/organisations/admin/${orgId}/invite`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type CancelInviteToOrg = (a: {
  orgId: string;
  requestId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const cancelInviteToOrg: CancelInviteToOrg = ({ orgId, requestId }) => {
  const path = `/api/public/api/organisations/admin/${orgId}/invite/${requestId}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetOrgUser = (a: {
  orgId: string;
  userId: string | number;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrgUser>>>>;

export const getOrgUser: GetOrgUser = ({ orgId, userId }) => {
  const path = `/api/public/api/organisations/admin/${orgId}/user/${userId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateOrgUser = (a: {
  params: { orgId: string; userId: number | string };
  data: { name: string; isAdmin: boolean };
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const updateOrgUser: UpdateOrgUser = ({ params, data }) => {
  const path = `/api/public/api/organisations/admin/${params.orgId}/user/${params.userId}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------
