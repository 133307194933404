import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page } from '@react-pdf/renderer';
import { FooterCompany } from '../../comps';
import { isEqual } from 'lodash';
import { PdfAssessment } from 'src/api/types';
import { ColorSet } from 'src/redux/reducers/theme';
import { useAuth } from 'src/state/auth';

interface AssessmentEndProps {
  assessment: PdfAssessment;
  colors: ColorSet;
}

export const AssessmentEnd: React.FC<AssessmentEndProps> = memo(
  ({ assessment, colors }) => {
    const { profile } = useAuth();

    return (
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <FooterCompany
          companyName={profile?.activeWorkspace?.name || ''}
          title={assessment.name}
          label="RISK ASSESSMENT"
          companyImage={''}
          colors={colors}
        />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
