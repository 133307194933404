import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useVersion from '../useVersion';
import { useAuth } from 'src/state/auth';
import { HeaderData } from 'src/redux/types';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import _ from 'lodash';
import { useStateSelector } from 'src/redux';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';

const useNavLocation = () => {
  const locationPaths = useStateSelector(({ app }) => app.location.paths);
  const { profile, appLoaded, flatOrganisations } = useAuth();
  const { pathname, search } = useLocation();
  const { version } = useVersion();
  const navigate = useNavigate();
  const { setLocationHeader } = useActionsApp();
  const workspaceId = profile?.activeWorkspace?.id;

  useEffect(() => {
    if (!appLoaded || !workspaceId || !flatOrganisations.length) {
      setLocationHeader([]);
      return;
    } else {
      const buildPath = (id: string): HeaderData[] => {
        const currentUnit = flatOrganisations.find((org) => org.id === id);
        if (!currentUnit) return [];

        const isWorkspace = currentUnit.recordType === 'W';
        const isOrganization = currentUnit.recordType === 'O';
        const path = isWorkspace ? '/risk-assessments' : `/organisation/${currentUnit.id}`;

        // For non-root items, the '/home' path should never make them active
        const currentPath: HeaderData = {
          key: currentUnit.id,
          titleComponent: {
            title: currentUnit.name,
            iconHome: currentUnit.parentId === null,
            isActive: isWorkspace
              ? false
              : pathname === `/organisation/${currentUnit.id}` ||
                (pathname === '/home' && currentUnit.parentId === null),
          },
          isStart: currentUnit.parentId === null,
          isNext: currentUnit.parentId !== null,
          onClick: () => {
            if (isWorkspace) {
              navigate('/risk-assessments');
              mpEvent(MPEvents.NavClick, { value: 'Workspace', tags: ['NAVIGATION'] });
            } else if (isOrganization) {
              navigate(`/organisation/${currentUnit.id}`);
              mpEvent(MPEvents.NavClick, { value: 'Organisation', tags: ['NAVIGATION'] });
            }
          },
          path,
        };

        if (currentUnit.parentId) {
          const parentPath = buildPath(currentUnit.parentId);
          return [...parentPath, currentPath];
        }

        // Special case for the root item (first in the list)
        if (currentPath.isStart) {
          return [
            {
              ...currentPath,
              titleComponent: {
                ...currentPath.titleComponent,
                iconHome: true,
                // Only the root item should be active on the '/home' path
                isActive: pathname === '/home' || pathname === `/organisation/${currentUnit.id}`,
              },
              path: '/home',
              onClick: () => mpEvent(MPEvents.NavClick, { value: 'Home', tags: ['NAVIGATION'] }),
            },
          ];
        }

        return [currentPath];
      };

      let ndata = buildPath(workspaceId);

      // Ensure iconHome is true for the first item in the list
      if (ndata.length > 0) {
        ndata = [
          {
            ...ndata[0],
            isStart: true,
            isNext: false,
            titleComponent: {
              ...ndata[0].titleComponent,
              iconHome: true,
              // Explicitly ensure the first nav item is active on the '/home' path
              isActive: pathname === '/home' || pathname === `/organisation/${ndata[0].key}`,
            },
            // First nav item always points to '/home'
            path: '/home',
          },
          ...ndata.slice(1),
        ];
      }

      // TIMELINE
      if (pathname.startsWith('/timeline')) {
        const path = '/timeline';
        const title = 'Timeline';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      // RISK ASSESSMENTS
      if (pathname.startsWith('/risk-assessments')) {
        const path = '/risk-assessments';

        ndata.push({
          key: path,
          titleComponent: {
            title: 'Risk Assessments',
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: 'Risk Assessments', tags: ['NAVIGATION', 'ASSESSMENT'] }),
          path,
        });

        if (locationPaths.assessment) {
          const path = `/risk-assessments/${locationPaths.assessment.id}${search}`;
          const title = locationPaths.assessment.name;

          ndata.push({
            key: path,
            titleComponent: {
              title,
              isActive: pathname + search === path,
            },
            isNext: true,
            onClick: () =>
              mpEvent(MPEvents.NavClick, { value: `Risk Assessments:Assessment`, tags: ['NAVIGATION', 'ASSESSMENT'] }),
            path,
          });
        }
        if (locationPaths.scenario && locationPaths.assessment) {
          const path = `/risk-assessments/${locationPaths.assessment.id}/scenario/${locationPaths.scenario.id}${search}`;
          const title = locationPaths.scenario.name;

          ndata.push({
            key: path,
            titleComponent: {
              title,
              isActive: pathname + search === path,
            },
            isNext: true,
            onClick: () =>
              mpEvent(MPEvents.NavClick, {
                value: `Risk Assessments:Assessment:Scenario`,
                tags: ['NAVIGATION', 'SCENARIO'],
              }),
            path,
          });
        }
      }

      // SCENARIO LIBRARY
      if (pathname.startsWith('/scenario-library')) {
        const path = '/scenario-library';
        const title = 'Scenario Library';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      // RMP
      if (pathname.startsWith('/risk-management')) {
        const path = '/risk-management';
        const title = 'Risk Management';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });

        if (locationPaths.rmpControl) {
          const path = `/risk-management/control/${locationPaths.rmpControl.id}`;
          const title = locationPaths.rmpControl.name;

          ndata.push({
            key: path,
            titleComponent: {
              title: locationPaths.rmpControl.name,
              isActive: pathname === path,
            },
            isNext: true,
            onClick: () => mpEvent(MPEvents.NavClick, { value: `Risk Management:Control`, tags: ['NAVIGATION'] }),
            path,
          });
        }
      }

      // INCIDENTS
      if (pathname.startsWith('/incidents')) {
        const path = '/incidents';
        const title = 'Security Incidents';

        ndata.push({
          key: 'incidents',
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });

        if (locationPaths.incident) {
          const path = `/incidents/${locationPaths.incident.id}`;
          const title = locationPaths.incident.name;

          ndata.push({
            key: path,
            titleComponent: {
              title,
              isActive: pathname === path,
            },
            isNext: true,
            onClick: () => mpEvent(MPEvents.NavClick, { value: `Security Incidents:Incident`, tags: ['NAVIGATION'] }),
            path,
          });
        }
      }

      // CONTROL FRAMEWORKS
      if (pathname.startsWith('/control-frameworks')) {
        const path = '/control-frameworks';
        const title = 'Control Frameworks';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });

        if (locationPaths.framework) {
          const id = locationPaths.framework.id;
          const path = `/control-frameworks/${id}`;
          const isActive = pathname === path;
          const title = locationPaths.framework.name;

          ndata.push({
            key: id,
            titleComponent: {
              title,
              isActive,
            },
            isNext: true,
            onClick: () => mpEvent(MPEvents.NavClick, { value: `Control Frameworks:Framework`, tags: ['NAVIGATION'] }),
            path,
          });
        } else {
          if (pathname === '/control-frameworks/all-controls') {
            const path = '/control-frameworks/all-controls';
            const isActive = pathname === path;
            const title = 'All Controls';

            ndata.push({
              key: 'all-controls',
              titleComponent: {
                title,
                isActive,
              },
              isNext: true,
              onClick: () => mpEvent(MPEvents.NavClick, { value: title }),
              path,
            });
          }
        }

        if (locationPaths.frameworkControl) {
          const frameworkControlId = locationPaths.frameworkControl.id;
          const path = `/control-frameworks/all-controls/${frameworkControlId}`;
          const title = locationPaths.frameworkControl.name;

          ndata.push({
            key: frameworkControlId,
            titleComponent: {
              title,
            },
            isNext: true,
            onClick: () => mpEvent(MPEvents.NavClick, { value: `All Controls:Control`, tags: ['NAVIGATION'] }),
            path,
          });
        }
      }

      // AUDIT
      if (pathname.startsWith('/audit/workspace')) {
        const path = '/audit/workspace';
        const title = 'Audit Log';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      // WORKSPACE
      if (pathname.startsWith('/workspace')) {
        const path = '/workspace';
        const title = 'Workspace';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      // HELP
      if (pathname.startsWith('/get-started')) {
        const path = '/get-started';
        const title = 'Help';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      setLocationHeader(ndata);
    }
  }, [
    workspaceId,
    flatOrganisations,
    pathname,
    search,
    version,
    locationPaths,
    appLoaded,
    navigate,
    setLocationHeader,
    profile,
  ]);

  return null;
};

export default useNavLocation;
