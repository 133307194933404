import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import _ from 'lodash';
import { useAuth } from 'src/state/auth';
import { useMutation } from 'react-query';
import { updateWorkspaceUser, workspaceUsersLink } from 'src/api/workspace';
import { TableUserItem } from 'src/common';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';
import { WorkspaceQuery } from 'src/graphql/_generated';
import { useCache } from 'src/graphql/useCache';

type WUser = NonNullable<WorkspaceQuery['workspace']>['users'][number];

type UseColumns = (a: { data?: WUser[]; workspaceId: string }) => {
  columns: ColumnDef<WUser>[];
  GTColumnSelectAnchorExported: JSX.Element;
  profileModalId: number | null;
  setProfileModalId: (id: number | null) => void;
};

const defaultColumns = ['workspace_user_name', 'email', 'role'];

export const useMembersColumns: UseColumns = ({ data = [], workspaceId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${TableIds.wsMembers}-columns`, defaultColumns);
  const [profileModalId, setProfileModalId] = useState<number | null>(null);
  const { profile } = useAuth();

  const { refetchQuery } = useCache();

  const resetCache = () => refetchQuery({ keys: ['profile', 'workspace'] });

  const [setPermissions] = useMutation(updateWorkspaceUser, {
    onSuccess: resetCache,
  });

  const [linkUsers] = useMutation(workspaceUsersLink, {
    onSuccess: resetCache,
  });

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm];

    const list: ColumnDef<WUser>[] = [
      {
        id: 'workspace_user_name',
        header: 'Name',
        accessorFn: (row) => row.orgUnitIdentity?.name,
        cell: ({ row }) => {
          return (
            <TableUserItem
              onClick={() => {
                setProfileModalId(+row.original.id);
                mpEvent(MPEvents.ButtonClick, {
                  button: '[user click]',
                  value: row.original.id,
                  tags: ['WORKSPACE', 'USER'],
                });
              }}
              profilePhotoPath={row.original.profilePhotoPath}
              name={`${row.original.orgUnitIdentity?.name} ${profile?.user?.id === row.original.id ? '(You)' : ''}`}
            />
          );
        },
        sortingFn: sortingFns.text,
      },

      {
        id: 'email',
        header: 'Email',
        accessorFn: (row) => row.email || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'role',
        header: 'Role',
        accessorFn: (row) => row.orgUnitIdentity?.assumedRole || row.orgUnitIdentity?.baseRole,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },

      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const user = row.original;
          const userId = row.original.id;

          const targetIsAdmin = row.original.orgUnitIdentity?.baseRole === 'Workspace Administrator';
          const canManageUsers = profile?.permissions.canManageUsers;

          const onMakeAdminClick =
            canManageUsers && !targetIsAdmin
              ? () =>
                  setPermissions({
                    params: { userId, workspaceId },
                    data: { name: user.name, isAdmin: true },
                  })
              : undefined;

          const onUnmakeAdminClick =
            canManageUsers && targetIsAdmin
              ? () =>
                  setPermissions({
                    params: { userId: user.id, workspaceId },
                    data: { name: user.name, isAdmin: false },
                  })
              : undefined;

          const onRemoveUserClick = () => {
            linkUsers({
              workspaceId,
              remove_users: [+user.id],
              add_users: [],
            });
          };

          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm
                onMakeAdminClick={onMakeAdminClick}
                onUnmakeAdminClick={onUnmakeAdminClick}
                onRemoveUserClick={onRemoveUserClick}
              />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, profile]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'workspace_user_name', title: 'Name' },
        { key: 'email', title: 'Email' },
        { key: 'role', title: 'Role' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
    profileModalId,
    setProfileModalId,
  };
};
