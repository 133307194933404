import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from './comps';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MaterialInput from 'src/components/form/MaterialInput';
import { Poppins, Spacer } from 'src/common';
import { useMutation } from 'react-query';
import Button from 'src/components/form/Button';
import { Plan } from 'src/api/types';
import _ from 'lodash';
import { Guard } from './Guard';
import { RestrictAccessSelect, OrganisationSelect } from '../select';
import { useAuth } from 'src/state/auth';
import useOrgSwitch from 'src/hooks/useWorkspaceSwitch';
import { WorkspaceTypeSelect } from '../select/WorkspaceTypeSelect';
import { workspaceCreate } from 'src/api/workspace';
import { useCache } from 'src/graphql/useCache';

const StyledModal = styled(Modal)`
  width: 566px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

interface WorkspaceCreateModalProps {
  onClose: () => void;
}

export const WorkspaceCreateModal: React.FC<WorkspaceCreateModalProps> = ({ onClose }) => {
  const [err, setErr] = useState('');
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const { profile, features } = useAuth();
  const { refetchQuery } = useCache();

  const { isLoading: isLoadingSwitch } = useOrgSwitch({
    onError: (err) => setErr(err.message || 'Something went wrong'),
  });

  const validationSchema = Yup.object({
    name: Yup.string().required('Required').max(200, 'Max 200 characters'),
    primary_contact: Yup.string().email('Invalid email address').required('Required'),
    billing_contact: Yup.string().email('Invalid email address').nullable(),
    parent_id: Yup.string().required('Required'),
    plan: Yup.string().required('Required'),
    is_restricted: Yup.boolean().required('Required'),
    currency: Yup.string()
      .max(3, 'Currency must be at most 3 characters long')
      .matches(/^[^\s]*$/, 'Currency must not contain spaces')
      .nullable(),
  });

  const [create] = useMutation(workspaceCreate, {
    onSuccess: () => {
      refetchQuery();
      onClose();
    },
    onError: (err: any) => setErr(err.message || 'Something went wrong'),
  });

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleSubmit,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      name: '',
      primary_contact: '',
      billing_contact: null,
      plan: '' as Plan,
      parent_id: '',
      admin_id: +profile?.user.id!,
      is_restricted: undefined as boolean | undefined,
      currency: undefined,
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      return create(values);
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen>
          <Spacer $px={40} />
          <div className="h-padding">
            <Poppins className="m-title" px={28}>
              Create a Workspace
            </Poppins>
            <Spacer $px={30} />
            <MaterialInput
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Workspace name"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
            />
            <Spacer $px={20} />
            <MaterialInput
              name="primary_contact"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.primary_contact}
              placeholder="Primary contact email address"
              error={touched.primary_contact && Boolean(errors.primary_contact)}
              helperText={touched.primary_contact && errors.primary_contact}
              fullWidth
            />
            <Spacer $px={20} />
            <OrganisationSelect
              placeholder="Parent organisation"
              value={values.parent_id}
              onChange={(o) => {
                setFieldValue('parent_id', o.value);
                setTimeout(() => setFieldTouched('parent_id', true));
              }}
              onBlur={handleBlur('parent_id')}
              error={touched.parent_id && Boolean(errors.parent_id)}
              helperText={touched.parent_id && errors.parent_id}
              isSearchable
            />
            <Spacer $px={20} />
            <RestrictAccessSelect
              value={values.is_restricted}
              onChange={(val) => {
                setFieldValue('is_restricted', val.value);
              }}
              onBlur={handleBlur('is_restricted')}
              error={touched.is_restricted && Boolean(errors.is_restricted)}
              helperText={touched.is_restricted && errors.is_restricted}
            />
            <Spacer $px={20} />
            <WorkspaceTypeSelect
              value={values.plan}
              onChange={(o) => {
                setFieldValue('plan', o.value);
                setTimeout(() => setFieldTouched('plan', true));
              }}
              onBlur={handleBlur('plan')}
              error={touched.plan && Boolean(errors.plan)}
              helperText={touched.plan && errors.plan}
            />
            {features['app.workspace.currency'] && (
              <>
                <Spacer $px={20} />
                <MaterialInput
                  id="currency"
                  placeholder="Currency"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.currency}
                  fullWidth
                  error={touched.currency && Boolean(errors.currency)}
                  helperText={touched.currency && errors.currency}
                />
              </>
            )}

            <Spacer $px={40} />
            <div className="btns">
              <Button secondary onClick={() => setIsCloseRequested(true)} primary>
                CANCEL
              </Button>
              <Button
                primary
                disabled={!isValid || isSubmitting || !dirty || isLoadingSwitch}
                onClick={() => handleSubmit()}
              >
                CREATE
              </Button>
            </div>

            {!!err && (
              <>
                <Spacer $px={15} />
                <div className="error">{err}</div>
              </>
            )}
          </div>

          <Spacer $px={40} />
        </StyledModal>
      }
    />
  );
};
