import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OrgWorkspaceNode } from '../types';
import Button from 'src/components/form/Button';
import { useAuth } from 'src/state/auth';
import useWorkspaceSwitch from 'src/hooks/useWorkspaceSwitch';
import { mpEvent } from 'src/utils/mixpanel';
import { LSKEY_INITAIL_URL } from 'src/utils/auth';
import { MPEvents } from 'src/utils/mixpanel';
import { useMutation } from 'react-query';
import { workspaceInviteAccept, workspaceJoinRequest, workspaceJoinRequestCancel } from 'src/api/workspace';
import { useCache } from 'src/graphql/useCache';

const Div = styled.div`
  button {
    width: 100% !important;
  }
`;

interface ActionsProps {
  data: OrgWorkspaceNode['data'];
}

type Action = 'WORKSPACE_OPEN' | 'WORKSPACE_ACCEPT_INVITE' | 'WORKSPACE_ACCESS_REQUEST' | 'WORKSPACE_ACCESS_CANCEL';

export const Actions: React.FC<ActionsProps> = ({ data }) => {
  const { profile, isPlatformAdmin } = useAuth();
  const [action, setAction] = useState<Action | null>(null);

  const { switchOrg, isLoading: isLoadingSwitch } = useWorkspaceSwitch();
  const { refetchQuery } = useCache();

  const resetCache = () => refetchQuery({ keys: ['profile', 'flatOrganisations'] });

  useEffect(() => {
    if (data.recordType === 'W') {
      const getActionType = () => {
        if (profile?.user.requests.some((el) => el.workspaceId === data.id)) return 'WORKSPACE_ACCESS_CANCEL';

        if (profile?.user.invites.some((el) => el.workspaceId === data.id)) return 'WORKSPACE_ACCEPT_INVITE';

        if (data.actionsOnThisOrgUnit.canAccess || isPlatformAdmin) return 'WORKSPACE_OPEN';

        if (data.actionsOnThisOrgUnit.canRequest) return 'WORKSPACE_ACCESS_REQUEST';

        return null;
      };

      setAction(getActionType());
    }
  }, [data, isPlatformAdmin, profile]);

  const [wjr, { isLoading: isLoadingWjr }] = useMutation(workspaceJoinRequest, {
    onSuccess: resetCache,
    onError: (err) => console.log('ERR', err),
  });

  const [mutateWorkspaceJoinRequestCancel, { isLoading: isLoadingWorkspaceJoinRequestCancel }] = useMutation(
    workspaceJoinRequestCancel,
    {
      onSuccess: resetCache,
      onError: (err) => console.log('ERR', err),
    },
  );

  const [mutateWorkspaceInviteAccept, { isLoading: isLoadingWorkspaceInviteAccept }] = useMutation(
    workspaceInviteAccept,
    {
      onSuccess: resetCache,
      onError: (err) => console.log('ERR', err),
    },
  );

  if (data.recordType === 'O') return null;

  return (
    <Div>
      {action === 'WORKSPACE_OPEN' && (
        <Button
          onClick={() => {
            window.localStorage.setItem(LSKEY_INITAIL_URL, '/risk-assessments');
            switchOrg(data.id);
            mpEvent(MPEvents.WorkspaceSwitch, { workspaceID: data.id });
          }}
          secondary
          disabled={isLoadingSwitch}
        >
          OPEN
        </Button>
      )}

      {action === 'WORKSPACE_ACCESS_CANCEL' && (
        <Button
          onClick={() => mutateWorkspaceJoinRequestCancel(data.id)}
          secondary
          disabled={isLoadingWorkspaceJoinRequestCancel}
        >
          CANCEL REQUEST
        </Button>
      )}

      {action === 'WORKSPACE_ACCEPT_INVITE' && (
        <Button
          onClick={() =>
            mutateWorkspaceInviteAccept({
              orgId: data.id,
              requestId: profile?.user.invites.find((el) => el.workspaceId === data.id)?.id,
            })
          }
          secondary
          disabled={isLoadingWorkspaceInviteAccept}
        >
          ACCEPT INVITE
        </Button>
      )}

      {action === 'WORKSPACE_ACCESS_REQUEST' && (
        <Button
          onClick={() => {
            wjr(data.id);
            mpEvent(MPEvents.ButtonClick, {
              button: 'Request access',
            });
          }}
          secondary
          disabled={isLoadingWjr}
        >
          REQUEST ACCESS
        </Button>
      )}
    </Div>
  );
};
