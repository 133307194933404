import React, { ChangeEvent, useRef, useState } from 'react';
import { GradientText, GradientTextAction, InputLabel, Poppins, Spacer, StyledAvatar } from 'src/common';
import Button from 'src/components/form/Button';
import styled from 'styled-components';
import { getProfileCredentials } from 'src/utils/auth';
import MaterialInput from 'src/components/form/MaterialInput';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { ConfirmDeleteModal } from 'src/components/modals';
import { RestrictAccessSelect, WorkspaceTypeSelect } from 'src/components/select';
import _ from 'lodash';
import { TabWrap } from 'src/components/TabsMenu';
import { updateWorkspace } from 'src/api/workspace';
import { useAuth } from 'src/state/auth';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { setCurrencySymbol } from 'src/utils/misc';
import { DailyDigest } from './comps';
import moment from 'moment';
import { FormValues } from './types';
import { WorkspaceQuery } from 'src/graphql/_generated';
import { useCache } from 'src/graphql/useCache';

const Div = styled(TabWrap)`
  background-color: ${({ theme }) => theme.colors.white};

  .prof-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    &__input {
      display: none;
    }
  }

  form {
    .contents {
      display: grid;
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr;
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 13px;
      gap: 13px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    form {
      .contents {
        grid-gap: 20px;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    form {
      .contents {
        grid-gap: 10px;
        grid-template-columns: 1fr;
      }
    }
  }
`;

interface WorkspaceDetailsProps {
  workspace: NonNullable<WorkspaceQuery['workspace']>;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  primary_contact: Yup.string().email('Invalid email address').required('Required'),
  is_restricted: Yup.boolean(),
  currency: Yup.string()
    .max(3, 'Currency must be at most 3 characters long')
    .matches(/^[^\s]*$/, 'Currency must not contain spaces')
    .nullable(),
  notification_schedule_time: Yup.string()
    .test('is-valid-time', 'Invalid time format', (value) => {
      console.log('value', value);
      if (!value) return true;
      return moment(value, 'HH:mm', true).isValid();
    })
    .nullable(),
});

export const WorkspaceDetails: React.FC<WorkspaceDetailsProps> = ({ workspace }) => {
  const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined);
  const [err, setErr] = useState('');
  const [deleteOrgId, setDeleteOrgId] = useState<string | undefined>(undefined);
  const { profile, features } = useAuth();
  const { refetchQuery } = useCache();

  const inputRef = useRef<HTMLInputElement>(null);

  const [update] = useMutation(updateWorkspace, {
    onSuccess: () => refetchQuery({ keys: ['workspace', 'flatOrganisations', 'profile'] }),
    onError: (err: any) => setErr(err.message || 'Something went wrong'),
  });

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        setSelectedImage(event.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: workspace.name || '',
      primary_contact: workspace.primaryContact || '',
      plan: 'Trial',
      is_restricted: workspace.isRestricted,
      domain: workspace.parent?.domain,
      currency: workspace.currency || '',
      notification_schedule_time: workspace.notificationScheduleTime,
    } as FormValues,
    onSubmit: (values) => {
      const currency = values.currency;

      if (currency !== workspace.currency) {
        setCurrencySymbol(currency);
        mpEvent(MPEvents.CurrencyChange, {
          currency,
        });
      }

      return update({ ...values, id: workspace.id });
    },
    enableReinitialize: true,
  });

  const {
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    isValid,
    isSubmitting,
    dirty,
  } = formik;

  return (
    <>
      {deleteOrgId && (
        <>
          <ConfirmDeleteModal
            title={null}
            onConfirm={() => {
              setDeleteOrgId(undefined);
            }}
            onClose={() => setDeleteOrgId(undefined)}
          >
            <Poppins px={18} weight={600} color="cflowerBlue">
              Are you sure you want to delete{' '}
              <Poppins px={18} weight={600} color="prussianBlue">
                {workspace.name}
              </Poppins>{' '}
              ?
            </Poppins>
          </ConfirmDeleteModal>
        </>
      )}
      <Div>
        <FormikProvider value={formik}>
          <GradientText>DETAILS</GradientText>
          <Spacer $px={32} />
          <div className="prof-img">
            <StyledAvatar src={selectedImage}>{getProfileCredentials(workspace.name)}</StyledAvatar>
            <GradientTextAction onClick={() => inputRef.current?.click()} px={14}>
              Update Photo
            </GradientTextAction>
            <input
              className="prof-img__input"
              ref={inputRef}
              type="file"
              onChange={handleImageChange}
              accept="image/*"
            />
          </div>

          <Spacer $px={32} />
          <form onSubmit={handleSubmit}>
            <div className="contents">
              <div>
                <InputLabel>NAME</InputLabel>
                <MaterialInput
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  fullWidth
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
                <Spacer $px={20} />
                <InputLabel>PRIMARY CONTACT EMAIL ADDRESS</InputLabel>
                <MaterialInput
                  id="primary_contact"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.primary_contact}
                  type="email"
                  fullWidth
                  error={touched.primary_contact && Boolean(errors.primary_contact)}
                  helperText={touched.primary_contact && errors.primary_contact}
                />
                <Spacer $px={20} />
                <InputLabel>ORGANISATION</InputLabel>
                <MaterialInput value={profile?.activeOrganisation?.name} disabled fullWidth />
                {features['app.notifications.scheduler'] && (
                  <DailyDigest workspaceId={workspace.id} members={workspace.users} />
                )}
              </div>
              <div>
                <InputLabel>RESTRICT ACCESS</InputLabel>
                <RestrictAccessSelect
                  value={values.is_restricted}
                  onChange={(val) => {
                    setFieldValue('is_restricted', val.value);
                  }}
                  onBlur={handleBlur('is_restricted')}
                  error={touched.is_restricted && Boolean(errors.is_restricted)}
                  helperText={touched.is_restricted && errors.is_restricted}
                />
                <Spacer $px={20} />
                <InputLabel>WORKSPACE TYPE</InputLabel>
                <WorkspaceTypeSelect
                  value={values.plan}
                  onChange={(val) => setFieldValue('plan', val.value)}
                  onBlur={handleBlur('plan')}
                  error={touched.plan && Boolean(errors.plan)}
                  helperText={touched.plan && errors.plan}
                />
                {features['app.workspace.currency'] && (
                  <>
                    <Spacer $px={20} />
                    <InputLabel>CURRENCY</InputLabel>
                    <MaterialInput
                      id="currency"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currency}
                      fullWidth
                      error={touched.currency && Boolean(errors.currency)}
                      helperText={touched.currency && errors.currency}
                    />
                  </>
                )}
              </div>
            </div>
            {err && (
              <>
                <Spacer $px={20} />
                <div className="err">{err}</div>
              </>
            )}
            <Spacer $px={50} />
            <div className="controls">
              <Button
                primary
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
                $constWidth={220}
                onClick={() =>
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Update',
                    tags: ['WORKSPACE'],
                  })
                }
              >
                UPDATE
              </Button>
              {/* <GradientTextAction
              $underline
              onClick={() => {
                alert('Not implemented');
              }}
            >
              Leave workspace
            </GradientTextAction> */}
            </div>
          </form>
        </FormikProvider>
      </Div>
    </>
  );
};
