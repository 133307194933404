import { Assessment } from 'src/api/types';
import { formatDate, numeralFormat } from 'src/utils/misc';
import { generateScenarioMarkdown } from './MDScenario';
import { getExternalPath } from '../util';

interface MDAssessmentProps {
  assessment: Assessment;
  userName?: string;
}

export const generateAssessmentMarkdown = ({ assessment, userName = 'User' }: MDAssessmentProps): string => {
  const {
    name,
    description,
    executive_summary,
    review_date,
    expected_loss,
    scenarios_count,
    started_date,
    scenarios = [],
  } = assessment;

  // Format the current date for the export date
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];
  const formattedTime = currentDate.toTimeString().split(' ')[0].substring(0, 5);
  const exportDateTime = `${formattedDate}, ${formattedTime}`;

  // Create Cover Page with Logo
  let markdown = `<img src="https://avatars.githubusercontent.com/u/56755149?s=200&v=4" width="100" />\n\n`;

  markdown += `#### RISK ASSESSMENT\n`;
  markdown += `${name || '_No data_'}\n\n`;
  markdown += `#### Description\n`;
  markdown += `${description || '_No data_'}\n\n`;

  // Metadata table
  markdown += `| REPORT DATE | START DATE | EDITED | UPDATED | EXPORTED BY |\n`;
  markdown += `|-------------|------------|--------|---------|-------------|\n`;
  markdown += `|${formatDate(new Date())}|${formatDate(started_date)}| ${formatDate(assessment.meta?.edited_at, {
    formatType: 'datetime',
  })} | ${formatDate(assessment.meta?.updated_at, {
    formatType: 'datetime',
  })} | ${userName} |\n\n`;

  markdown += `---\n\n`; // Page break in MD

  // Table of Contents
  markdown += `## Table of Contents\n\n`;

  markdown += `[Executive Summary](#executive-summary)\n\n`;
  markdown += `[Assessment Summary](#assessment-summary)\n\n`;
  markdown += `[Risk Scenarios](#scenarios)\n\n`;
  markdown += `${assessment.scenarios.map((scenario) => `- [${scenario.name}](#${scenario.name.toLowerCase().replace(/ /g, '-')})`).join('\n\n')}\n\n`;

  markdown += `---\n\n`; // Page break in MD

  // Executive Summary
  markdown += `<h2 id="executive-summary">Executive Summary</h2>\n\n`;
  markdown += `${executive_summary || '_No data_'}\n\n`;
  markdown += `---\n\n`; // Page break in MD

  // Assessment Summary
  markdown += `<h2 id="assessment-summary">Assessment Summary</h2>\n\n`;
  markdown += `| REVIEW DATE | EDITED | UPDATED |\n`;
  markdown += `|-------------|--------|--------|\n`;
  markdown += `| ${formatDate(review_date)} | ${exportDateTime} | ${exportDateTime} |\n\n`;

  // Risk metrics
  markdown += `### Expected loss\n\n`;
  markdown += `- **${numeralFormat(expected_loss)}**\n\n`;

  // Add scenario status
  markdown += `### Scenario status\n\n`;

  const completedScenarios = 0; // We don't know which are complete
  const missingDataScenarios = scenarios_count;

  markdown += `- **${completedScenarios}** Complete\n`;
  markdown += `- **${missingDataScenarios}** Missing data\n\n`;

  // Scenarios without controls
  markdown += `### Scenarios without controls\n\n`;
  markdown += `- **0** of ${scenarios_count} scenarios\n\n`;

  // Scope section
  markdown += `### Scope\n\n`;
  markdown += description ? `${description}\n\n` : `_No data_\n\n`;

  // Risk scenarios table
  markdown += `<h2 id="scenarios">Risk Scenarios</h2>\n\n`;
  if (scenarios && scenarios.length > 0) {
    markdown += `| Name | Risk Owner | Sources | Events | Consequences | Expected Loss |\n`;
    markdown += `|------|------------|---------|--------|--------------|---------------|\n`;

    scenarios.forEach((scenario) => {
      markdown += `| [${scenario.name || '-'}](${getExternalPath(`risk-assessments/${assessment.id}/scenario/${scenario.id}`)}) | ${scenario.risk_owner || '-'} | ${scenario.source || '-'} | ${scenario.event || '-'} | ${scenario.consequence || '-'} | ${numeralFormat(scenario.expected_loss || 0)} |\n`;
    });

    markdown += `\n`;
  } else {
    markdown += `_No scenarios available_\n\n`;
  }

  markdown += `---\n\n`;

  markdown += `${assessment.scenarios.map((scenario) => generateScenarioMarkdown({ scenario })).join('\n\n')}`;

  return markdown;
};
