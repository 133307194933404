import React, { useEffect, useRef, useState } from 'react';
import { GradientTextAction, InputLabel, Poppins, Spacer, StyledAvatar, UserProfileModalSkeleton } from 'src/common';
import { Guard } from '../Guard';
import { useAuth } from 'src/state/auth';
import { queryCache, useMutation, useQuery } from 'react-query';
import { updateProfile } from 'src/api/auth';
import { QKeys, ReceiveNotificationsValue } from 'src/api/types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getProfileCredentials } from 'src/utils/auth';
import { flattenUserAssociations, handleImageChange } from './util';
import MaterialInput from 'src/components/form/MaterialInput';
import Button from 'src/components/form/Button';
import { UserContextSelect, UserCtxOption } from 'src/components/select';
import styled from 'styled-components';
import { getWorkspaceUser, updateWorkspaceUser } from 'src/api/workspace';
import { GenericSelect } from 'src/components/select/GenericSelect';
import { formatDate } from 'src/utils/misc';
import { getOrgUser, updateOrgUser } from 'src/api/organisation';
import { getUserAssociations } from 'src/api/user';
import { Modal } from '../comps';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { AuditSection } from './sections';
import { useCache } from 'src/graphql/useCache';

const ModalWrap = styled(Modal)`
  width: 1350px;
  min-height: 200px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .p-head {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  .img-input {
    display: none;
  }

  form {
    .contents {
      display: grid;
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr;
    }

    .btm-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;

    &--left {
      text-align: left;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    form {
      .contents {
        grid-gap: 20px;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    form {
      .contents {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const validationSchema = Yup.object({
  name: Yup.string().required('Required').max(100, 'Max 100 characters'),
});

export interface InitValues {
  name: string;
  profile_photo_path?: string | null;
  email: string;
  accepted_date?: string;
  isOrgAndimn?: boolean;
  isWorkspaceAdmin?: boolean;
  defaultName: string;
  receive_notifications: ReceiveNotificationsValue;
}

interface UserModalProps {
  onClose: () => void;
  externalUserId?: number;
  workspaceId?: string;
  orgId?: string;
}

export const UserModal: React.FC<UserModalProps> = ({ onClose, externalUserId, workspaceId, orgId }) => {
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [userCtx, setUserCtx] = useState<UserCtxOption | undefined>(undefined);
  const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<UserCtxOption[]>([]);
  const [initError, setInitError] = useState('');
  const [err, setErr] = useState('');
  const [initialValues, setInitialValues] = useState<InitValues | undefined>(undefined);
  const { profile, user, isPlatformAdmin, features } = useAuth();
  const { refetchQuery } = useCache();

  useEffect(() => {
    if (workspaceId) {
      const opt = options.find((o) => o.type === 'W' && o.value === workspaceId);
      setUserCtx(opt);
    }
    if (orgId) {
      const opt = options.find((o) => o.type === 'O' && o.value === orgId);
      setUserCtx(opt);
    }
  }, [workspaceId, orgId, options]);

  const userId = (externalUserId || Number(profile?.user.id)) as number;
  const isOwnProfile = userId === Number(profile?.user.id);
  const inputRef = useRef<HTMLInputElement>(null);

  useQuery([QKeys.UserAssociations, userId], () => getUserAssociations(userId), {
    onSuccess: (data) => setOptions(flattenUserAssociations(data)),
  });

  useEffect(() => {
    setInitError('');

    if (userCtx?.type === 'W') {
      getWorkspaceUser({ workspaceId: userCtx.value, userId })
        .then((res) => {
          console.log('RES-W', res);
          setInitialValues({
            name: res.member?.aka || res.name,
            profile_photo_path: res.profile_photo_path,
            email: res.email,
            accepted_date: formatDate(res.member.accepted_date),
            isOrgAndimn: res.permissions['app.organisation.edit_users'] === '1',
            isWorkspaceAdmin: res.permissions['app.organisation.manage_users'] === '1',
            defaultName: res.name,
            receive_notifications: res.member.notifications === true ? 'true' : 'false',
          });
        })
        .catch((err) => setInitError(err?.message || 'Something went wrong'));
    }
    if (userCtx?.type === 'O') {
      getOrgUser({ orgId: userCtx.value, userId })
        .then((res) => {
          console.log('RES-O', res);
          setInitialValues({
            name: res.member?.aka || res.name,
            profile_photo_path: res.profile_photo_path,
            email: res.email,
            isOrgAndimn: res.permissions['app.organisation.edit_users'] === '1',
            defaultName: res.name,
            receive_notifications: res.receive_notifications,
          });
        })
        .catch((err) => setInitError(err?.message || 'Something went wrong'));
    }
  }, [userCtx]);

  useEffect(() => {
    // GLOBAL SELF
    if (!userCtx && profile && user) {
      setInitialValues({
        name: profile.user.name,
        profile_photo_path: profile.user.profilePhotoPath,
        email: profile.user.email,
        defaultName: profile.user.name,
        receive_notifications: user.receive_notifications,
        // receive_notifications: profile.user.orgUnitIdentity?.receiveNotifications ? 'true' : 'false',
      });
    }
  }, [profile, user, userCtx]);

  const [updateSelf] = useMutation(updateProfile, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.User);
      refetchQuery();
      onClose();
    },
    onError: (err: any) => setErr(err.message || 'Something went wrong'),
  });

  const [updateWUser] = useMutation(updateWorkspaceUser, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.User);
      refetchQuery();
      onClose();
    },
    onError: (err: any) => setErr(err.message || 'Something went wrong'),
  });

  const [updateOUser] = useMutation(updateOrgUser, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.User);
      refetchQuery();
      onClose();
    },
    onError: (err: any) => setErr(err.message || 'Something went wrong'),
  });

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    isValid,
    isSubmitting,
    dirty,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    validationSchema,
    initialValues: initialValues as InitValues,
    onSubmit: (values) => {
      setErr('');
      if (!userCtx) {
        return updateSelf({
          name: values.name,
          receive_notifications: values.receive_notifications,
        });
      }

      if (userCtx?.type === 'W') {
        return updateWUser({
          params: { workspaceId: userCtx.value, userId },
          data: {
            name: values.name,
            isAdmin: !initialValues?.isOrgAndimn ? values.isWorkspaceAdmin : undefined,
            notifications: values.receive_notifications === 'true',
          },
        });
      }
      if (userCtx?.type === 'O') {
        return updateOUser({
          params: { orgId: userCtx?.value, userId },
          data: {
            isAdmin: !!values.isOrgAndimn,
            name: values.name,
          },
        });
      }

      return;
    },
    enableReinitialize: true,
  });

  const canEditWorkspaceUser =
    profile?.user?.workspaces.find((el) => el.id === userCtx?.value)?.actionsOnThisWorkspace?.canEditSettings ||
    isPlatformAdmin;

  const notifications = () => {
    const canEdit = isOwnProfile || canEditWorkspaceUser;
    return (
      <>
        <Spacer $px={20} />
        <InputLabel>DAILY DIGEST NOTIFICATIONS</InputLabel>
        <GenericSelect
          disabled={!canEdit}
          onChange={(value) => {
            const newValue = value.value;
            setFieldValue('receive_notifications', newValue);
            mpEvent(MPEvents.NotificationToggle, { enabled: newValue === 'true' });
          }}
          options={[
            { label: 'On', value: 'true' },
            { label: 'Off', value: 'false' },
          ]}
          value={values.receive_notifications}
        />
      </>
    );
  };

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <ModalWrap onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
          <Spacer $px={40} />
          <div className="h-padding">
            {values ? (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="p-head">
                    <Poppins px={18} weight={600}>
                      {values?.defaultName?.toUpperCase()}
                    </Poppins>
                    <Poppins weight={500} px={14} color="cflowerBlue">
                      for
                    </Poppins>
                    <div css="flex:1;display: grid; max-width: 280px;margin-right: 30px;">
                      <UserContextSelect
                        options={options}
                        isClearable={isOwnProfile}
                        placeholder={'Global'}
                        onChange={setUserCtx}
                        value={userCtx?.value}
                      />
                    </div>
                  </div>
                  <Spacer $px={20} />
                  <StyledAvatar src={values.profile_photo_path || selectedImage}>
                    {getProfileCredentials(initialValues?.name)}
                  </StyledAvatar>
                  <Spacer $px={13} />
                  <GradientTextAction
                    onClick={() => {
                      alert('Not implemented');
                      // inputRef.current?.click();
                    }}
                    px={14}
                  >
                    Update Photo
                  </GradientTextAction>
                  <input
                    className="img-input"
                    ref={inputRef}
                    type="file"
                    onChange={(e) => handleImageChange(e, setSelectedImage)}
                    accept="image/*"
                  />
                  <Spacer $px={18} />
                  <Poppins weight={500} px={14} color="cflowerBlue">
                    You are currently viewing {isOwnProfile ? 'your' : 'user’s'} profile details and activity
                    {userCtx ? (
                      <>
                        {' '}
                        within{' '}
                        <Poppins px={14} weight={600}>
                          {userCtx.label}
                          <Poppins weight={500} px={14} color="cflowerBlue">
                            {userCtx.type === 'O' ? ' organisation' : ' workspace'}
                          </Poppins>
                        </Poppins>
                      </>
                    ) : (
                      ' across all your organisations and workspaces'
                    )}
                    .
                  </Poppins>

                  {initError && (
                    <>
                      <Spacer $px={27} />
                      <div className="error error--left">{initError}</div>
                    </>
                  )}

                  <Spacer $px={27} />
                  {userCtx?.type === 'O' && (
                    <div className="contents">
                      <div>
                        <InputLabel>NAME</InputLabel>
                        <MaterialInput
                          id="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          fullWidth
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          data-cy="name-input"
                        />
                        <Spacer $px={20} />
                        <InputLabel>ROLE</InputLabel>
                        <GenericSelect
                          disabled={!canEditWorkspaceUser}
                          onChange={(value) => setFieldValue('isOrgAndimn', value.value === 'yes')}
                          options={[
                            { label: 'Organisation Admin', value: 'yes' },
                            { label: 'Collaborator', value: 'no' },
                          ]}
                          value={values.isOrgAndimn ? 'yes' : 'no'}
                          placeholder=""
                        />
                      </div>
                      <div>
                        <InputLabel>EMAIL ADDRESS</InputLabel>
                        <MaterialInput value={values?.email} fullWidth disabled />
                      </div>
                    </div>
                  )}
                  {userCtx?.type === 'W' && (
                    <div className="contents">
                      <div>
                        <InputLabel>NAME</InputLabel>
                        <MaterialInput
                          id="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          fullWidth
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          data-cy="name-input"
                        />
                        <Spacer $px={20} />
                        <InputLabel>ROLE</InputLabel>
                        <GenericSelect
                          disabled={!canEditWorkspaceUser}
                          onChange={(value) => setFieldValue('isWorkspaceAdmin', value.value === 'yes')}
                          options={[
                            { label: 'Workspace Admin', value: 'yes' },
                            { label: 'Collaborator', value: 'no' },
                          ]}
                          value={values.isWorkspaceAdmin ? 'yes' : 'no'}
                          placeholder=""
                        />
                        {features['app.notifications.scheduler'] && <>{notifications()}</>}
                      </div>

                      <div>
                        <InputLabel>EMAIL ADDRESS</InputLabel>
                        <MaterialInput value={values?.email} fullWidth disabled />
                        <Spacer $px={20} />
                        <InputLabel>JOIN DATE</InputLabel>
                        <MaterialInput value={values.accepted_date} fullWidth disabled />
                      </div>
                    </div>
                  )}
                  {!userCtx && (
                    <div className="contents" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                      <div>
                        <InputLabel>NAME</InputLabel>
                        <MaterialInput
                          id="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          fullWidth
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          data-cy="name-input"
                        />
                        {features['app.notifications.scheduler'] && notifications()}
                      </div>
                      <div>
                        <InputLabel>EMAIL ADDRESS</InputLabel>
                        <MaterialInput value={values?.email} fullWidth disabled />
                      </div>
                    </div>
                  )}

                  {err && (
                    <>
                      <Spacer $px={20} />
                      <div className="error error--left">{err}</div>
                    </>
                  )}
                  <Spacer $px={50} />
                  <div className="btm-controls">
                    <Button
                      primary
                      type="submit"
                      disabled={!isValid || isSubmitting || !dirty}
                      data-cy="update-button"
                      $constWidth={220}
                      onClick={() =>
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Update',
                          value: userId,
                          tags: ['USER'],
                        })
                      }
                    >
                      UPDATE
                    </Button>
                  </div>
                  {features['app.audit.report'] && (!!userCtx?.type || isOwnProfile) && (
                    <AuditSection userId={userId} userCtx={userCtx} />
                  )}
                </form>
              </>
            ) : (
              <>
                <UserProfileModalSkeleton />
                <Spacer $px={20} />
              </>
            )}
          </div>
        </ModalWrap>
      }
    />
  );
};
