import React, { useState } from 'react';
import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import { PickMenuItemParams } from './types';
import { pickMenuItem } from './util';

const StyledMenu = styled(Menu)<{ $top: number }>`
  transform: translateY(${({ $top }) => $top}px);
  & .MuiPaper-root {
    border-radius: 3px;
    box-shadow: 0px 0px 37px ${({ theme }) => theme.colors.shadow};
    background-color: ${({ theme }) => theme.colors.white};
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    margin-right: 10px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.cflowerBlue};
    opacity: 0.2;
  }
`;

type CommonMenuProps = {
  configuredList?: (PickMenuItemParams | undefined | false)[];
  onViewClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onEditClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onDeleteClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onVariablesClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onRiskTolenceClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onCsvClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onUnlinkScenarioClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onUnlickControl?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onUnlinkPlanClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onUnlinkIncidentClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onPublishClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onPdfClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onAssistanceClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onCopyClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onMakeAdminClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onUnmakeAdminClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onRemoveUserClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onAcceptRequestClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onRejectRequestClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onCancelInviteClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onAddToRMPClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onOpenModifierClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onInviteClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onAddControlClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onAddAssessmentClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onWizardClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onWorkspaceVariablesClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onExportMdClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  top?: number;
} & React.ComponentProps<typeof Menu>;

const CommonMenu: React.FC<CommonMenuProps> = ({
  top = 10,
  configuredList,
  onDeleteClick,
  onViewClick,
  onEditClick,
  onVariablesClick,
  onRiskTolenceClick,
  onCsvClick,
  onPublishClick,
  onPdfClick,
  onAssistanceClick,
  onCopyClick,
  onUnlinkScenarioClick,
  onUnlickControl,
  onUnlinkPlanClick,
  onUnlinkIncidentClick,
  onMakeAdminClick,
  onUnmakeAdminClick,
  onRemoveUserClick,
  onAcceptRequestClick,
  onRejectRequestClick,
  onCancelInviteClick,
  onAddToRMPClick,
  onOpenModifierClick,
  onInviteClick,
  onAddControlClick,
  onAddAssessmentClick,
  onWizardClick,
  onWorkspaceVariablesClick,
  onExportMdClick,
  ...other
}) => {
  const [activeItem, setActiveItem] = useState('');

  const resetActiveItem = () => {
    setActiveItem('');
  };

  return (
    <StyledMenu
      $top={top}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoFocus={false}
      {...other}
    >
      {configuredList ? (
        <div>{configuredList.map((el) => el && pickMenuItem(el, { resetActiveItem, activeItem, setActiveItem }))}</div>
      ) : (
        <div>
          {onViewClick &&
            pickMenuItem({ type: 'VIEW', exe: onViewClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onPublishClick &&
            pickMenuItem({ type: 'PUBLISH', exe: onPublishClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onEditClick &&
            pickMenuItem({ type: 'EDIT', exe: onEditClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onCopyClick &&
            pickMenuItem({ type: 'DUPLICATE', exe: onCopyClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onDeleteClick &&
            pickMenuItem({ type: 'DELETE', exe: onDeleteClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onRiskTolenceClick &&
            pickMenuItem(
              { type: 'RISK_TOLERANCE', exe: onRiskTolenceClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onVariablesClick &&
            pickMenuItem({ type: 'VARIABLES', exe: onVariablesClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onPdfClick && pickMenuItem({ type: 'PDF', exe: onPdfClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onCsvClick && pickMenuItem({ type: 'CSV', exe: onCsvClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onAssistanceClick &&
            pickMenuItem(
              { type: 'ASSISTANCE', exe: onAssistanceClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onUnlinkScenarioClick &&
            pickMenuItem(
              { type: 'UNLINK_SCENARIO', exe: onUnlinkScenarioClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onUnlickControl &&
            pickMenuItem(
              { type: 'UNLINK_CONTROL', exe: onUnlickControl },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onUnlinkPlanClick &&
            pickMenuItem(
              { type: 'UNLINK_PLAN', exe: onUnlinkPlanClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onUnlinkIncidentClick &&
            pickMenuItem(
              { type: 'UNLINK_INCIDENT', exe: onUnlinkIncidentClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onMakeAdminClick &&
            pickMenuItem({ type: 'MAKE_ADMIN', exe: onMakeAdminClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onUnmakeAdminClick &&
            pickMenuItem(
              { type: 'UNMAKE_ADMIN', exe: onUnmakeAdminClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onRemoveUserClick &&
            pickMenuItem(
              { type: 'REMOVE_USER', exe: onRemoveUserClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onAcceptRequestClick &&
            pickMenuItem(
              { type: 'ACCEPT_USER', exe: onAcceptRequestClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onRejectRequestClick &&
            pickMenuItem(
              { type: 'REJECT_USER', exe: onRejectRequestClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onCancelInviteClick &&
            pickMenuItem(
              { type: 'CANCEL_INVITE', exe: onCancelInviteClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onInviteClick &&
            pickMenuItem({ type: 'INVITE', exe: onInviteClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onAddToRMPClick &&
            pickMenuItem({ type: 'ADD_TO_RMP', exe: onAddToRMPClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onOpenModifierClick &&
            pickMenuItem(
              { type: 'OPEN_MODIFIER', exe: onOpenModifierClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onAddControlClick &&
            pickMenuItem(
              { type: 'ADD_CONTROL', exe: onAddControlClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onAddAssessmentClick &&
            pickMenuItem(
              { type: 'ADD_ASSESSMENT', exe: onAddAssessmentClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onWizardClick &&
            pickMenuItem({ type: 'WIZARD', exe: onWizardClick }, { resetActiveItem, activeItem, setActiveItem })}
          {onWorkspaceVariablesClick &&
            pickMenuItem(
              { type: 'WORKSPACE_VARIABLES', exe: onWorkspaceVariablesClick },
              { resetActiveItem, activeItem, setActiveItem },
            )}
          {onExportMdClick &&
            pickMenuItem({ type: 'MD', exe: onExportMdClick }, { resetActiveItem, activeItem, setActiveItem })}
        </div>
      )}
    </StyledMenu>
  );
};

export default CommonMenu;
