import { CSVVarHeaders } from './types';

const standardVariableNames: Record<number, string> = {
  500001: 'Annual revenue',
  500002: 'Number of staff',
  500003: 'Annual wage bill',
  500004: 'Average number of working days per year',
  500005: 'Annual cyber spend',
  500006: 'Average staff hardware cost',
  500007: 'Number of customers',
  500008: 'Average contract value',
  500009: 'Number of data records',
  500010: 'Release cycle cost',
  500011: 'Release cycle length',
  500012: 'Research and development budget',
  500013: 'Average cost to procure supplier',
  500014: 'Average invoice value',
  500015: 'Largest regular invoice value',
  500016: 'Cyber insurance limit',
  500017: 'Cyber insurance excess',
  500018: 'Cyber insurance premium',
};

export const generateCsvTemplateLink = () => {
  let csvString = Object.values(CSVVarHeaders).join(',') + '\n';

  Object.entries(standardVariableNames).forEach(([id, name]) => {
    csvString += `${name},,\n`;
  });

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  return URL.createObjectURL(blob);
};

export const getStandardVariableNames = (): string[] => {
  return Object.values(standardVariableNames);
};
