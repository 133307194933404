import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const MarkdownContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.prussianBlue};
  border-radius: 4px;
  padding: 24px;
  overflow-y: auto;
  background-color: white;
  line-height: 1.6;
  color: #24292e;
  font-size: 16px;

  /* Document structure */
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaecef;
    font-weight: 600;
  }

  h2 {
    font-size: 1.5em;
    margin: 24px 0 16px;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaecef;
    font-weight: 600;
  }

  h3 {
    font-size: 1.25em;
    margin: 16px 0;
    font-weight: 600;
  }

  /* Tables */
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 16px 0;
  }

  table th {
    background-color: #f6f8fa;
    padding: 6px 13px;
    text-align: left;
    border: 1px solid #dfe2e5;
    font-weight: 600;
  }

  table td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }

  /* Lists */
  ul,
  ol {
    padding-left: 2em;
    margin: 16px 0;
  }

  li {
    margin: 0.25em 0;
  }

  /* Code blocks */
  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
    margin: 16px 0;
  }

  code {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 3px;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }

  /* Text formatting */
  p {
    margin: 16px 0;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  /* Dividers */
  hr {
    height: 0.25em;
    padding: 0;
    margin: 24px 0;
    background-color: #e1e4e8;
    border: 0;
  }

  /* Blockquotes */
  blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
    margin: 16px 0;
  }

  /* Links */
  a {
    color: #0366d6;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  /* Images */
  img {
    max-width: 100%;
    box-sizing: content-box;
    background-color: #fff;
  }

  /* Page break class */
  .page-break {
    page-break-after: always;
    break-after: page;
    height: 0;
    margin: 0;
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const Button = styled.button`
  padding: 8px 16px;
  margin-left: 8px;
  background-color: ${({ theme }) => theme.colors.prussianBlue};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

interface MDProps {
  value: string;
}

export const MD: React.FC<MDProps> = ({ value }) => {
  const [content, setContent] = useState<string>(value);

  useEffect(() => {
    setContent(value);
  }, [value]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(content);
  };

  const handleDownload = () => {
    const blob = new Blob([content], { type: 'text/markdown' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'assessment.md';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <MarkdownContainer>
        <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {content}
        </Markdown>
      </MarkdownContainer>
      <ButtonContainer>
        <Button onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
        <Button onClick={handleDownload}>Download</Button>
      </ButtonContainer>
    </>
  );
};
