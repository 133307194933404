import React from 'react';
import styled from 'styled-components';
import { Modifiers, ModifiersTransfer, ProjectedScenario, TransferLimitType } from 'src/api/types';
import Action from './Action';
import { GradientText } from 'src/common';
import { FieldArray, useFormikContext } from 'formik';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { AiSvg } from 'src/assets/dynamicSvg';
import { useAuth } from 'src/state/auth';

const ActionText = styled(GradientText)`
  text-transform: none !important;
  cursor: pointer;
  margin-bottom: 15px;
  display: inline-block;

  .suggest-modifier-icon {
    width: 12px;
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Div = styled.div`
  display: grid;
  grid-gap: 10px;
  button[type='submit'] {
    width: 120px;
  }
`;

const options = [
  {
    label: 'Limit',
    value: 'TRANSFER_LIMIT',
  },
];

interface TransferModifierProps {
  projectedScenario: ProjectedScenario;
  disabled?: boolean;
  isDirtyOutside?: boolean;
  noResult?: boolean;
  recommendedModifiers?: Modifiers | null;
}

const TransferModifier: React.FC<TransferModifierProps> = ({
  projectedScenario,
  disabled,
  noResult,
  recommendedModifiers,
}) => {
  const { values, setFieldValue } = useFormikContext<ModifiersTransfer<TransferLimitType>>();
  const { features } = useAuth();

  const handleAddModifier = () => {
    const newModifier = { type: 'TRANSFER_LIMIT', data: { excess: '', tpc: '' }, note: '' };
    const updatedTransfer = [...values.transfer, newModifier];
    setFieldValue('transfer', updatedTransfer);
  };

  return (
    <Div>
      <FieldArray name="transfer">
        {({ form, ...helpers }) => (
          <>
            {values.transfer.map((el, idx) => (
              <Action
                key={el.type + idx}
                idx={idx}
                options={options}
                projectedScenario={projectedScenario}
                onRemove={() => helpers.remove(idx)}
                disabled={disabled}
                noResult={noResult}
              />
            ))}
            <div style={{ marginLeft: values.transfer.length > 0 ? '30px' : '0' }}>
              {!disabled && values.transfer.length < options.length && (
                <>
                  <ActionText
                    weight={500}
                    px={14}
                    onClick={() => {
                      handleAddModifier();
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Modifier:Add',
                        tags: ['MODIFIERS'],
                      });
                    }}
                  >
                    + Add Limit Modifier
                  </ActionText>
                  {features['app.ai.suggestModifiers'] && recommendedModifiers && (
                    <ActionText
                      weight={500}
                      px={14}
                      style={{ marginLeft: '32px', display: 'inline-flex', alignItems: 'center' }}
                      onClick={() => {
                        setFieldValue('transfer', recommendedModifiers?.transfer);
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Modifier:Suggest',
                          tags: ['MODIFIERS', 'AI'],
                        });
                      }}
                    >
                      <AiSvg className="suggest-modifier-icon" />
                      Suggest Modifier
                    </ActionText>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </FieldArray>
    </Div>
  );
};

export default TransferModifier;
