import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page, Text, View } from '@react-pdf/renderer';
import { Footer, HeaderCompany } from '../../comps';
import { isEqual } from 'lodash';
import { ColorSet } from 'src/redux/reducers/theme';
import { ProfileQuery } from 'src/graphql/_generated';
import { useAuth } from 'src/state/auth';

interface RmpHeadProps {
  numberOfStrategies: number;
  colors: ColorSet;
}

export const RmpHead: React.FC<RmpHeadProps> = memo(
  ({ numberOfStrategies, colors }) => {
    const { profile } = useAuth();

    return (
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <HeaderCompany
          companyName={profile?.activeOrganisation?.name || ''}
          title="Risk Management Plan"
          companyImage={''}
          colors={colors}
        />
        <View style={{ ...pdfStyles.section, flex: 1, justifyContent: 'flex-end' }}>
          <Text style={pdfStyles.gradText}>
            NUMBER OF STRATEGIES: <Text style={{ ...pdfStyles.value, fontSize: 12 }}> {numberOfStrategies}</Text>
          </Text>
          <View style={pdfStyles.spacer1} />
        </View>

        <Footer colors={colors} />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
