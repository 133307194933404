import React from 'react';
import { CountLabel, Spacer } from 'src/common';
import { RowRequest } from 'src/components/orgRows';
import { useAuth } from 'src/state/auth';
import styled from 'styled-components';

const Div = styled.div`
  .orgs-list {
    display: grid;
    grid-gap: 10px;
  }
`;

interface RequestsProps {
  onSuccess: () => void;
}

export const Requests: React.FC<RequestsProps> = ({ onSuccess }) => {
  const { profile } = useAuth();
  const myRequests = profile?.user.requests || [];

  // console.log('myRequests', myRequests);

  return (
    <Div>
      {myRequests.length > 0 && (
        <>
          <Spacer $px={30} />
          <CountLabel label="request" length={myRequests.length} />
          <Spacer $px={5} />
          <div className="orgs-list">
            {myRequests.map((el) => (
              <RowRequest
                key={el.workspaceId}
                id={el.workspaceId}
                name={el.workspaceName}
                img={null}
                onSuccess={onSuccess}
              />
            ))}
          </div>
        </>
      )}
    </Div>
  );
};
