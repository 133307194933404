import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuth } from 'src/state/auth';

const REMINDER_KEY = '__cydea_wizard_reminder__';

const useWizardReminder = () => {
  const { profile } = useAuth();
  const [isSnoozed, setIsSnoozed] = useState(true);

  useEffect(() => {
    if (profile?.activeWorkspace?.id) {
      const now = moment().toISOString();

      const data = window.localStorage.getItem(REMINDER_KEY) || '{}';
      const existingReminders = JSON.parse(data);
      const reminder = existingReminders[profile?.activeWorkspace?.id];

      if (reminder && moment(reminder).isAfter(now)) {
        setIsSnoozed(true);
      } else {
        setIsSnoozed(false);
      }

      const updatedReminders = Object.entries(existingReminders).reduce((acc, [workspaceId, reminderTime]) => {
        if (moment(reminderTime as string).isAfter(now)) {
          // @ts-ignore
          acc[workspaceId] = reminderTime;
        }
        return acc;
      }, {});

      if (Object.keys(updatedReminders).length !== Object.keys(existingReminders).length) {
        window.localStorage.setItem(REMINDER_KEY, JSON.stringify(updatedReminders));
      }
    }
  }, [profile]);

  const saveReminder = (value: number, span: 'hour' | 'day') => {
    const workspace_id = profile?.activeWorkspace?.id;

    if (workspace_id) {
      const date = moment().add(value, span).toISOString();

      const data = window.localStorage.getItem(REMINDER_KEY) || '{}';
      const existingReminders = JSON.parse(data);
      const newReminders = { ...existingReminders, [workspace_id]: date };
      window.localStorage.setItem(REMINDER_KEY, JSON.stringify(newReminders));
    }
  };

  return { isSnoozed, saveReminder };
};

export default useWizardReminder;
