import React, { useState } from 'react';
import styled from 'styled-components';
import { CsvVariable } from '../types';
import { CSVVarHeaders } from '../types';
import { useDropzone } from 'react-dropzone';
import * as Yup from 'yup';
import Papa from 'papaparse';
import { GradientTextAction, Poppins, Spacer } from 'src/common';
import { Dropzone, ErrorItem, ErrorLog, ValidationErrorItem } from 'src/common/dropzone';
import { generateCsvTemplateLink, getStandardVariableNames } from '../util';

const Container = styled.div``;

const csvVariableSchema = Yup.object({
  [CSVVarHeaders.Name]: Yup.string().required('Name is required'),
  [CSVVarHeaders.Value]: Yup.number().required('Value is required').typeError('Value must be a number'),
  [CSVVarHeaders['Currency (yes/no)']]: Yup.string()
    .required('Currency field is required')
    .oneOf(['yes', 'no'], 'Currency must be "yes" or "no"'),
});

interface FormProps {
  onApply: (variables: CsvVariable[]) => void;
  onRevert: () => void;
}

const Form: React.FC<FormProps> = ({ onApply, onRevert }) => {
  const [vars, setVars] = useState<CsvVariable[]>([]);
  const [errors, setErrors] = useState<ValidationErrorItem[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);

  // Get standard variable names
  const standardVariableNames = getStandardVariableNames();

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop: (files) => {
      if (!files || files.length === 0) return;

      const file = files[0];

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const { data, errors: parseErrors } = results;

          if (parseErrors.length > 0) {
            setErrors([
              {
                rowIndex: 0,
                errors: [
                  {
                    path: 'File',
                    message: `CSV parsing error: ${parseErrors[0].message}`,
                  },
                ],
              },
            ]);
            setVars([]);
            return;
          }

          const validationErrors: ValidationErrorItem[] = [];
          const validVariables: CsvVariable[] = [];
          const unknownVariables: string[] = [];

          data.forEach((row: any, index: number) => {
            try {
              csvVariableSchema.validateSync(row, { abortEarly: false });

              const variableName = row[CSVVarHeaders.Name].trim();
              // Check if this is a recognized variable name
              if (!standardVariableNames.includes(variableName)) {
                unknownVariables.push(variableName);
              }

              validVariables.push({
                name: variableName,
                value: row[CSVVarHeaders.Value] ? Number(row[CSVVarHeaders.Value]) : undefined,
                currency: row[CSVVarHeaders['Currency (yes/no)']] === 'yes',
              });
            } catch (validationError) {
              if (validationError instanceof Yup.ValidationError) {
                validationErrors.push({
                  rowIndex: index,
                  errors: validationError.inner.map((err) => ({
                    path: err.path || 'Unknown',
                    message: err.message,
                  })),
                });
              }
            }
          });

          if (validationErrors.length > 0) {
            setErrors(validationErrors);
            setVars([]);
            onRevert();
          } else {
            setErrors([]);

            setVars(validVariables);
            console.log('OK', validVariables);
            onApply(validVariables);
          }
        },
        error: (error) => {
          setErrors([
            {
              rowIndex: 0,
              errors: [
                {
                  path: 'File',
                  message: `CSV parsing error: ${error.message}`,
                },
              ],
            },
          ]);
          setVars([]);
        },
      });
    },
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  return (
    <Container>
      <a href={generateCsvTemplateLink()} download="variables_template.csv" style={{ textDecoration: 'none' }}>
        <GradientTextAction $underline onClick={() => {}}>
          Download CSV Template
        </GradientTextAction>
      </a>
      <Spacer $px={20} />
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <Poppins px={14}>
          {isDragActive ? 'Drop the file here ...' : 'Drag and drop a file here, or click to select file'}
        </Poppins>
      </Dropzone>

      {errors.length > 0 && (
        <>
          <Spacer $px={15} />
          <ErrorLog>
            {errors.map((errorItem, index) => (
              <div key={index}>
                <Poppins color="error" px={16} weight={600}>
                  Error in row {errorItem.rowIndex + 1}:
                </Poppins>
                {errorItem.errors.map((e, idx) => (
                  <Poppins px={14} color="error" css="display: block" key={idx}>
                    {`${e.message}`}
                  </Poppins>
                ))}
              </div>
            ))}
          </ErrorLog>
        </>
      )}

      {/* {warnings.length > 0 && (
        <>
          <Spacer $px={15} />
          <Poppins px={14} color="warning">
            {warnings.map((warning, index) => (
              <div key={index}>{warning}</div>
            ))}
          </Poppins>
        </>
      )} */}

      {!!acceptedFiles.length && (
        <>
          <Spacer $px={15} />
          <Poppins px={14}>File selected: {acceptedFiles[0].name}</Poppins>
          <Spacer $px={15} />
          <Poppins px={14}>Variables ready for update: {vars.length}</Poppins>
        </>
      )}
    </Container>
  );
};

export default Form;
